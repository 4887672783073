import React, { useState, useEffect, useContext } from 'react'
import { Divider, Text, Flex, Select, FormControl, FormLabel, FormHelperText, Input, Stack } from '@chakra-ui/react'
import { TEXT, COLORS } from '../components/styles/global'
import { mdiPlusBoxOutline, mdiDeleteForever } from '@mdi/js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Icon from '@mdi/react'
import axios from 'axios';

import { ApiContext } from '../components/context/ApiContext';
import DashboardLayout from '../components/layouts/DashboardLayout'

export default function Stages() {
    const Api = useContext(ApiContext)
    const { paramDates, toggleRefresh } = Api

    const [cohortes, setcohortes] = useState([])
    const [selectedDate, setselectedDate] = useState(new Date())
    const [selectedCohorte, setselectedCohorte] = useState(null)
    const [datepickerShow, setdatepickerShow] = useState(false)

    const handleAddDate = async () => {
        if (selectedCohorte !== null) {
            await axios({
                method: "POST",
                data: {
                    paramtype: "date",
                    cohorte: selectedCohorte,
                    paramvalue: selectedDate
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'params/set'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    toggleRefresh()
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }
    }

    const removeParam = async (paramid) => {
        await axios({
            method: "DELETE",
            data: {
                paramid: paramid
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'params'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                toggleRefresh()
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })         
    }

    useEffect(() => {
        const getCohortes = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'cohortes'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setcohortes(res.data)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }

        getCohortes()
    }, [])

    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Configuration des stages</Text>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>
                Cette page vous permet de programmer les dates prévues de fin de plage de stage - ou dates d'évaluation. 
                Ces dates seront utilisées dans les statistiques afin de mémoriser l'état d'avancement des étudiants dans leurs APC.
            </Text>
            <Divider mt={5}/>
            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}} mt={5}>Ajouter une date d'évaluation</Text>
            <Flex direction="row" justifyContent="center" alignItems="center" borderRadius={10} borderWidth={1} p={3}>
                <Flex flexDirection="column" flex={0.8} mr={5}>
                    <FormControl id="listName">
                        <FormLabel color={COLORS.pharmBlue}>Cohorte</FormLabel>
                        <Select placeholder="Sélectionner la cohorte ciblée par la date d'évaluation" onChange={(val) => setselectedCohorte(val.target.value)}>
                            {cohortes.map((cohorte, index) => {
                                return (
                                    <option key={index}>{cohorte.cohorte}</option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={COLORS.pharmBlue}>Date: </FormLabel>
                        <Input value={selectedDate.toLocaleDateString()} readOnly onClick={() => setdatepickerShow(!datepickerShow)}/>
                        {datepickerShow &&
                            <DatePicker dateFormat="dd/mm/yyyy" timeFormat={false} selected={selectedDate} onChange={(date) => { setselectedDate(date); setdatepickerShow(false)}} inline/>
                        }
                        <FormHelperText>Correspond idéalement à une date après la fin du stage prévue, et avant que les évaluations suivantes débutent</FormHelperText>
                    </FormControl>
                </Flex>
                <Flex p={5} flex={0.2} flexDirection='column' style={{borderRadius: 10, cursor: 'pointer', color: 'white', backgroundColor: selectedCohorte === null ? "white" : "#333" }} userSelect="none" onClick={() => handleAddDate()}>
                    <Icon style={{alignSelf:'center'}} path={mdiPlusBoxOutline} color="white" size={1.2}/>
                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Créer la date</Text>
                </Flex>
            </Flex>
            <Divider mt={5}/>
            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}} mt={5}>Dates d'évaluation</Text>
            <Stack direction="column" overflowY="auto" overflowX="hidden" maxHeight="55vh">
            {paramDates.length !== 0 && paramDates.map((param, index) => {
                return (
                    <Flex key={index} flexDirection='row' width="100%" justifyContent="center" alignItems="center" borderRadius={10} borderWidth={1} p={3}>
                        <Flex flex={0.1} flexDirection='column' borderRightWidth={1} justifyContent="center" alignItems="center" userSelect="none" p={1}>
                            <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue}}>Cohorte</Text>
                            <Text style={{...TEXT.h2, color: COLORS.pharmBlue}}>{param.cohorte}</Text>
                        </Flex>
                        <Flex flex={0.7} flexDirection='column' ml={3}>
                            <Text style={{...TEXT.h3, color: COLORS.pharmBlue}}>{new Date(param.paramvalue).toLocaleDateString()}</Text>
                        </Flex>
                        <Flex p={5} pt={0} pb={0} flex={0.2} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => removeParam(param.paramid)}>
                            <Icon style={{alignSelf:'center'}} path={mdiDeleteForever} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Supprimer</Text>
                        </Flex>
                    </Flex>
                )
            })}
            </Stack>          
        </DashboardLayout>
    )
}