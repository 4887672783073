import React, { useState } from 'react'
import { Text, Box, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Divider, Button } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import DashboardLayout from '../components/layouts/DashboardLayout'
import Loading from '../components/Loading';

export default function Populate() {
    const [cohorte, setcohorte] = useState(0)
    const [popamount, setpopamount] = useState(1)
    const [masteramount, setmasteramount] = useState(1)
    const [apcamount, setapcamount] = useState(0)
    const [isGenerating, setisGenerating] = useState(false)

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function randomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
    }

    const handleGenerate = async () => {
        setisGenerating(true)
        let userUids = []
        let masterUids = []
        let uid;

        for (let i = 1; i <= popamount; i++) {
            uid = uuidv4()
            userUids.push(uid)
            await axios({
                method: "POST",
                data: {
                    uid: uid,
                    courriel: "email" + i + "@" + cohorte + ".com",
                    nom: "testuser" + i,
                    prenom: "testuser" + cohorte,
                    cohorte: cohorte,
                    authlevel: 0
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
            })
            .then((user) => {
                // console.log("Created user " + user)
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })
        }

        for (let i = 1; i <= masteramount; i++) {
            uid = uuidv4();
            masterUids.push(uid)
            await axios({
                method: "POST",
                data: {
                    uid: uid,
                    courriel: "email" + i + "@" + cohorte + ".com",
                    nom: "testmaster" + i,
                    prenom: "testmaster" + cohorte,
                    cohorte: cohorte,
                    authlevel: 1
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
            })
            .then((user) => {
                // console.log("Created master " + user)
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })        
        }

        for (let useruid of userUids) {
            for (let i = 1; i <= apcamount; i++) {
                let apcArray = new Array(7).fill(undefined)
                let date = randomDate(new Date(cohorte, 1, 1), new Date(cohorte, 12, 31))
                apcArray[getRandomInt(1,7)] = getRandomInt(1,5)
                // console.log("sending date " + date)
                await axios({
                    method: "POST",
                    data: {
                        masterId: masterUids[getRandomInt(0, masteramount-1)],
                        apcArray: apcArray,
                        date: date.toISOString().slice(0, 19).replace('T', ' ')
                    },
                    withCredentials: true,
                    url: process.env.REACT_APP_API_SERVER + 'users/' + useruid + "/apc"
                })
                .then(res => {
                    if (![200, 401].includes(res.status)) {
                        // console.log("Erreur serveur")
                    }
                })
                .then((user) => {
                    // console.log("Created user " + user)
                })
                .catch((err) => {
                    // console.log("Erreur: " + err)
                })
                // console.log("APC: userid: " + useruid + ", masterid: " + masterUids[getRandomInt(0, masteramount-1)] + ", apc_id: " + getRandomInt(1,6) + ", niveau_id: " + getRandomInt(1,5) + ", date: " + randomDate(new Date(cohorte, 1, 1), new Date(cohorte, 12, 31)))
            
            }
        }
        setisGenerating(false)
    }
    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue}}>Générer une fausse population d'utilisateurs</Text>
            <Box bg={COLORS.pharmRed} color="#eee" width="100%" p={4} borderRadius={10} userSelect='none'>
                <Text style={{...TEXT.bold}}>Attention</Text>
                <Text style={{...TEXT.regular}}>
                    Cette fonction ne devrait pas être utilisée dans un environnement en production. 
                    Utilisé pour fins de formation ou de développement dans un environnement distinct
                    et isolé. Aucune vérification n'est effectuée par rapport à l'intégrité des données - 
                    veuillez vous assurer que les champs soient bien remplis avec des valeurs pertinentes.
                </Text>
            </Box>
            <Box borderRadius={10} borderWidth={1} p={4} mt={5}>
                <Text style={{...TEXT.h3}}>Générer des utilisateurs</Text>
                <FormControl id="cohorte">
                    <FormLabel>Année de la cohorte</FormLabel>
                    <NumberInput defaultValue={0} onChange={(val) => setcohorte(val)}>
                    <NumberInputField />
                        <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormControl id="amount" mt={3}>
                    <FormLabel>Quantité d'utilisateurs à générer</FormLabel>
                    <NumberInput max={50} min={1} defaultValue={1} onChange={(val) => setpopamount(val)}>
                        <NumberInputField />
                        <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormControl id="amountmaitre" mt={3}>
                    <FormLabel>Quantité de maîtres de stage à générer</FormLabel>
                    <NumberInput max={20} min={1} defaultValue={1} onChange={(val) => setmasteramount(val)}>
                        <NumberInputField />
                        <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <Divider mt={3}/>
                <Text style={{...TEXT.h3}}>Options</Text>
                <FormControl id="amountapc" mt={3}>
                <FormLabel>Générer des APC aléatoires par utilisateur</FormLabel>
                    <NumberInput max={100} min={1} defaultValue={1} onChange={(val) => setapcamount(val)}>
                        <NumberInputField />
                        <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <Button
                    mt={4}
                    bgColor={COLORS.pharmBlue}
                    type="submit"
                    color="#eee"
                    _hover={{backgroundColor: COLORS.pharmBlueHover}}
                    onClick={() => handleGenerate()}
                    isDisabled={isGenerating}
                >
                    Générer
                </Button>
                {isGenerating && <Loading/>}
                <Text mt={2}>Attention - cette opération peut prendre du temps</Text>
            </Box>
        </DashboardLayout>
    )
}
