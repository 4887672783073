import React from "react"
import { Flex } from "@chakra-ui/react"
 
export default function DefaultLayout(props) {
  return (
    <Flex mt={50} height="50vh">
        <Flex direction="column" height="fit-content" alignItems={props.center ? props.center : "none"} justifyContent={props.center ? props.center : "none"} bgColor={props.bgColor} p={12} rounded={4} minH={500} minW="80%">
            {props.children}
        </Flex>
    </Flex>
  )
}

DefaultLayout.defaultProps = {
  bgColor: "rgb(235, 248, 255)"
}