import React, { useState, useRef, useEffect } from 'react'
import { Text, FormControl, Input, FormHelperText, FormLabel, Divider, Stack, Flex, Box } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import { useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Icon from '@mdi/react'
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from '@mdi/js';

import DashboardLayout from '../components/layouts/DashboardLayout'
import StudentEntry from '../components/stats/student/StudentEntry'

export default function StudentSearch() {
    let timeout;
    const [searchParams, setsearchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchField = useRef()
    const [isLoading, setisLoading] = useState(false)
    const [foundUsers, setfoundUsers] = useState([])
    const [searchError, setsearchError] = useState(false)
    const [searchPage, setsearchPage] = useState(1)
    const [searchValue, setsearchValue] = useState("")
    const [isFromList] = useState(searchParams.get('from') === '/student/list' && searchParams.get('read') === 'true')

    const doSearch = (searchValue) => {
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setsearchPage(1)
            setsearchValue(searchValue)
        }, 750);
    }

    useEffect(() => {
        const sendSearch = async () => {
            setisLoading(true)
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/search/' + searchValue + '/' + searchPage
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setsearchError(false)
                    setfoundUsers(res.data)
                    setsearchParams({ search: searchValue })
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setsearchError("Aucun résultat trouvé pour la recherche: " + searchValue)
                    setfoundUsers([])
                } else {
                    setsearchError("Impossible de se connecter au serveur")
                    // console.log("Erreur: " + err)
                }
            })
            .finally(() => {
                setisLoading(false)
                if (searchField.current !== null) {
                    searchField.current.focus()
                }
            })
        }

        if (searchValue !== "") {
            sendSearch()
        }
    }, [searchValue, searchPage])

    useEffect(() => {
        const addToList = async () => {
            await axios({
                method: "POST",
                data: {
                    listid: searchParams.get('listid')
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'stats/list/' + foundUsers[0].uid
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
                setsearchError("L'étudiant " + foundUsers[0].prenom + " " + foundUsers[0].nom + " a été ajouté à la liste!")
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })
        }

        // Redirections after specific searches
        if (foundUsers.length === 1) { 
            if (searchParams.get('from') === '/student/stats') {
                navigate('/student/stats?uid=' + foundUsers[0].uid)
                return
            }
            if (searchParams.get('from') === '/student/list' && searchParams.get('listid') !== null && searchParams.get('read') !== "true") {
                addToList()
            }
        }
    }, [foundUsers, navigate, searchParams])

    useEffect(() => {
        const getFromListid = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/list/' + searchParams.get('listid')
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setfoundUsers(res.data)
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setsearchError("La liste est vide! Veuillez ajouter des étudiants pour débuter")
                } else {
                    // console.log("Erreur: " + err)
                }
            })
            .finally(() => {
                setisLoading(false)
            })
        }

        if (isFromList && searchParams.get('listid') !== null) {
            setisLoading(true)
            getFromListid()
        }

        if (searchParams.get('search') !== null) {
            doSearch(searchParams.get('search'))
        }
    }, [isFromList, searchParams])

    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Recherche par étudiant</Text>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette page vous permet de trouver un étudiant pour afficher ses statistiques globales de performance et autres options rapides</Text>
            <Divider mt={5}/>
            {searchParams.get('from') !== null &&
                <Box bg={COLORS.pharmGreen} color="#eee" width="100%" p={4} borderRadius={10} userSelect='none'>
                    <Text style={{...TEXT.bold}}>Vous avez été redirigé</Text>
                    <Text style={{...TEXT.regular}}>
                        La recherche ci-bas vous renverra automatiquement à la page demandée lorsqu'un seul résultat sera trouvé.
                    </Text>
                </Box>
            }

            {!isFromList &&
                <FormControl id="studentSearch" mt={5} color={COLORS.pharmBlue}>
                    <FormLabel>Rechercher un étudiant</FormLabel>
                    <Input autoFocus ref={searchField} type="text" defaultValue={searchParams.get('search') === null ? "" : searchParams.get('search')} isDisabled={isLoading} onChange={(val) => doSearch(val.target.value)}/>
                    <FormHelperText mb={5}>Veuillez inscrire le nom, prénom ou identifiant unique. La recherche se lancera automatiquement à la fin de la saisie.</FormHelperText>
                </FormControl>
            }
            {searchError &&
                <Text mt={3} style={{...TEXT.bold, ...TEXT.vsmall, color: COLORS.pharmRed}}>{searchError}</Text>
            }

            <Stack direction="column" overflowY="auto" overflowX="hidden" maxHeight="55vh" color={COLORS.pharmBlue} mt={3}>
            {foundUsers.length > 0 &&
                foundUsers.map((user, i) => {
                    return (
                        <StudentEntry key={i} student={user} isFromList={isFromList} listid={searchParams.get('listid') ? searchParams.get('listid') : null} setsearchError={setsearchError}/>
                    )
                })
            }
            </Stack>
            {foundUsers.length > 0 && 
                <Flex mt={3} width="100%" direction='row' justifyContent="center" alignItems="center" userSelect="none">
                    {searchPage > 1 &&
                        <Flex flexDirection='row' p={2} mr={5} borderWidth={1} borderRadius={10} cursor="pointer" _hover={{color: "#eee", backgroundColor: COLORS.pharmBlueHover}} onClick={() => setsearchPage(searchPage-1)}>
                            <Icon path={mdiArrowLeftCircleOutline} color={COLORS.pharmBlue} _hover={{color:"#eee"}} size={1}/>
                            <Text ml={3}>Résultats précédents</Text>
                        </Flex>
                    }
                    {foundUsers.length === 9 &&
                        <Flex flexDirection="row" p={2} borderWidth={1} borderRadius={10} cursor="pointer" _hover={{color: "#eee", backgroundColor: COLORS.pharmBlueHover}} onClick={() => setsearchPage(searchPage+1)}>
                            <Text mr={3}>Résultats suivants</Text>
                            <Icon path={mdiArrowRightCircleOutline} color={COLORS.pharmBlue} _hover={{color:"#eee"}} size={1}/>
                        </Flex>
                    }    
                </Flex>
            }
        </DashboardLayout>
    )
}
