import React, { useState, useEffect, useContext } from 'react'
import { Flex, Divider, Text } from '@chakra-ui/react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { COLORS, TEXT } from '../components/styles/global'
import { ApiContext } from '../components/context/ApiContext'
import axios from 'axios'

import DashboardLayout from '../components/layouts/DashboardLayout'
import MasterBarStats from '../components/stats/master/MasterBarStats'

export default function MasterStats() {
    const [searchParams,] = useSearchParams();
    const navigate = useNavigate();
    let currentUid = searchParams.get('uid')
    const Api = useContext(ApiContext)
    const { niveau } = Api

    const [masterData, setmasterData] = useState({})
    const [masterStatData, setmasterStatData] = useState([])

    useEffect(() => {
        const getUserData = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + currentUid
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setmasterData(res.data)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }

        const getStatsData = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'stats/master/' + currentUid + '/stats'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    // console.log(res.data)
                    if (res.data.evalCount === undefined) res.data.evalCount = 0
                    if (res.data.studentCount === undefined) res.data.studentCount = 0
                    if (res.data.badgeCount === undefined) res.data.badgeCount = 0
                    let _chartData = []
                    _chartData.push({name: 'Étudiants évalués', count: res.data.studentCount},
                                    {name: 'Évaluations données', count: res.data.evalCount},
                                    {name: 'Badges octroyés', count: res.data.badgeCount})
                    niveauLoop: for (let i = 0; i <= 4; i++) {
                        for (let aniveau of res.data.niveauCounts) {
                            if (aniveau.niveau_id === i+1) {
                                _chartData.push({name: `Évaluations de niveau: ${niveau[i].titre}`, count: aniveau.cnt, color: niveau[i].color})
                                continue niveauLoop
                            }
                        }
                        _chartData.push({name: `Évaluations de niveau: ${niveau[i].titre}`, count: 0, color: niveau[i].color})
                    }
                    // console.log(_chartData)
                    setmasterStatData(_chartData)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }

        if (currentUid !== null && niveau.length !== 0) {
            getUserData()
            getStatsData()
        }
    }, [currentUid, niveau])

    useEffect(() => {
        if (currentUid === null) {
            navigate('/master/search?from=/master/stats')
        } 
    }, [currentUid, navigate])

    return (
        <DashboardLayout>
            <Flex flexDirection='row'>
                <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Statistiques pour le maître de stage {masterData.nom && ': ' + masterData.prenom + ' ' + masterData.nom + ' '}</Text>
            </Flex>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette page vous permet d'analyser les habitudes d'évaluation des différents maîtres de stage</Text>
            <Divider mt={5}/>
            <Text mt={5} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Statistiques globales d'évaluation</Text>
            {masterStatData.length !== 0 ?
                <MasterBarStats chartData={masterStatData}/>
            :
                <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue, marginLeft: 5}}>Le maître de stage n'a pas effectué d'évaluation à ce jour</Text>
            }
        </DashboardLayout>
    )
}
