import React, { useState, useEffect, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormHelperText,
    FormLabel,
    Text,
    Textarea
} from '@chakra-ui/react'
import { COLORS, TEXT } from '../styles/global';
import axios from 'axios';

import { ApiContext } from '../context/ApiContext';

export default function EditNiveauModal({isOpenNiveau, onCloseNiveau, niveauData}) {
    const [errorMessage, seterrorMessage] = useState("");
    const [isdisabled, setisdisabled] = useState(false);
    const Api = useContext(ApiContext)
    const { fetchNiveau } = Api

    const [niveauTitre, setniveauTitre] = useState("");
    const [niveauDesc, setniveauDesc] = useState("");
    const [niveauColor, setniveauColor] = useState("");

    const handleSubmit = async () => {
        setisdisabled(true)
        seterrorMessage("")
        if (niveauColor.length !== 7) {
            seterrorMessage("La couleur associée au niveau ne semble pas valide. Doit être de format hexadécimale #000000")
            setisdisabled(false)
            return
        }
        await axios({
            method: "PUT",
            data: {
                titre: niveauTitre,
                description: niveauDesc,
                color: niveauColor
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'niveaux/' + niveauData.id
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                seterrorMessage("Erreur serveur. Problème de connection, ou vous n'êtes pas autorisés à modifier le niveau de supervision")
            }
        })
        .then(() => {
            setisdisabled(false)
            fetchNiveau()
            onCloseNiveau()
        })
        .catch((err) => {
            if (err.response.status === 500) {
                seterrorMessage("Un des champs est invalide. Veuillez vous assurer que tous les champs soient remplis adéquatement.")
            } else {
                // console.log("Erreur: " + err)
            }
            setisdisabled(false)
        })
    }

    useEffect(() => {
        if (isOpenNiveau && niveauData !== null) {
            setniveauTitre(niveauData.titre)
            setniveauDesc(niveauData.description)
            setniveauColor(niveauData.color)
        }
    }, [isOpenNiveau]);

    return (
    <Modal isOpen={isOpenNiveau} onClose={onCloseNiveau} size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Modifier le niveau de supervision</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {errorMessage !== "" && <Text style={{...TEXT.bold, color: COLORS.pharmRed}}>{errorMessage}</Text>}
                <FormControl id="niveautitre" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Titre du niveau de supervision</FormLabel>
                    <Input type="text" min={0} max={255} value={niveauTitre} onChange={(val) => {setniveauTitre(val.target.value)}}/>
                    <FormHelperText>{niveauTitre.length | 0}/255 caractères</FormHelperText>
                </FormControl>
                <FormControl id="niveaudesc" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Description du niveau de supervision</FormLabel>
                    <Textarea min={0} max={512} value={niveauDesc} onChange={(val) => setniveauDesc(val.target.value)}/>
                    <FormHelperText>{niveauDesc.length | 0}/512 caractères</FormHelperText>
                </FormControl>
                <FormControl id="niveauicon" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Couleur (format #FFFFFF) du niveau</FormLabel>
                    <Input type="text" min={0} max={64} value={niveauColor} onChange={(val) => setniveauColor(val.target.value)}/>
                    <FormHelperText>{niveauColor.length | 0}/7 caractères</FormHelperText>
                </FormControl>                 
            </ModalBody>
            <ModalFooter>
            <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onCloseNiveau}>
                Fermer
            </Button>
            <Button backgroundColor={COLORS.pharmGreen} color="#eee" disabled={isdisabled} onClick={() => handleSubmit()}>Modifier le niveau de supervision</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    );
}
