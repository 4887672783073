import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import { COLORS, TEXT } from '../styles/global';
import axios from 'axios';

export default function DeleteUserModal({isOpenDelete, onCloseDelete, userUid}) {
    const [isdisabled, setisdisabled] = useState(false);

    const handleSubmitDelete = async () => {
        setisdisabled(true)
        await axios({
            method: "DELETE",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'users/' + userUid
        })
        .then(() => {
            setisdisabled(false)
            onCloseDelete()
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    return (
        <Modal isOpen={isOpenDelete} onClose={onCloseDelete} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Supprimer définitivement un utilisateur</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text style={{...TEXT.bold, color: COLORS.pharmRed}}>
                        ATTENTION! Cette opération tentera de supprimer un compte complètement. Cela
                        est irréversible et supprimera aussi toutes les opérations antérieures effectuées avec
                        ce compte, incluant les octrois de badges, les ajouts à une liste, les affiliations avec 
                        un étudiant (si applicable), les APC obtenus, les niveaux atteints et les badges obtenus.
                        La suppression d'un compte Maître de stage affectera aussi l'évaluation des étudiants
                        qui y ont été associé.
                        Ne poursuivez que si vous êtes certains que cette opération n'affectera pas d'autres comptes.
                    </Text>                
                </ModalBody>
                <ModalFooter>
                <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onCloseDelete}>
                    Fermer
                </Button>
                <Button backgroundColor={COLORS.pharmRed} color="#eee" disabled={isdisabled} onClick={handleSubmitDelete}>Confirmer la suppression</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
