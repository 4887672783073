import React, { useState, useEffect } from 'react'
import { CheckIsAuthenticated, authLogin, authLogout, authSetPassword } from './AuthActions'

export const AuthContext = React.createContext({})

export const Auth = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState({})
	const [userUid, setuserUid] = useState("")
	const [authLevel, setauthLevel] = useState(0)

    const checkAuth = async () => {
		setIsLoading(true)
		await CheckIsAuthenticated()
		.then(({ uid, authLevel }) => {
			setuserUid(uid)
			setauthLevel(authLevel)
			setIsAuthenticated(true)
		})
		.catch((err) => {
			setIsAuthenticated(false)
		})
		setIsLoading(false)
	}

    useEffect(() => {
        checkAuth()
    }, [])

    const login = async credentials => {
		let isAuth = false
		setIsLoading(true)
		await authLogin(credentials)
		.then(({ uid, auth_level }) => {
			if (uid !== undefined) setuserUid(uid)
			if (auth_level !== undefined) setauthLevel(auth_level)
			setIsAuthenticated(true)
			isAuth = true
		})
		.catch(error => {
			setErrorMessage(error)
			setIsAuthenticated(false)
		})
		setIsLoading(false)

		return isAuth
	}

	const setPassword = async (password, passwordconfirm) => {
		// TODO UID is set in the error handler, which is most likely suboptimal
		await authSetPassword(errorMessage, password, passwordconfirm).catch((err) => {
			setErrorMessage(err)
		})
	}

    const logout = () => {
        authLogout(userUid)
        setIsAuthenticated(false)
    }

    // const signUp = async credentials => {
	// 	setIsLoading(true)
	// 	await authSignUp(credentials)
    //     .then(setIsAuthenticated(true))
    //     .catch(error => {
    //         setErrorMessage(error)
    //         setIsAuthenticated(false)
    //     })
	// 	setIsLoading(false)
	// }

    const clearErrorMessage = () => {
      	setErrorMessage({})
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, userUid, authLevel, errorMessage, clearErrorMessage, login, logout, checkAuth, setPassword }}>
            {children}
        </AuthContext.Provider>
    )
}