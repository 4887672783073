import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Text, 
  Divider, 
  useDisclosure,   
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption 
} from '@chakra-ui/react';
import { mdiPlusBoxOutline, mdiWeb, mdiCellphone, mdiCheck, mdiTimerSand, mdiClose } from '@mdi/js';
import Icon from '@mdi/react'
import axios from 'axios';

import { TEXT, COLORS } from '../components/styles/global';
import DashboardLayout from '../components/layouts/DashboardLayout';
import BugsModal from '../components/bugs/BugsModal';

export default function Bugs() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [bugs, setbugs] = useState([]);

  useEffect(() => {
    const fetchBugReports = async () => {
      await axios({
        method: "GET",
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER + 'bugs/reports'
      })
      .then(res => {
          if (![200, 401].includes(res.status)) {
            // console.log("Erreur serveur")
          } else {
            setbugs(res.data)
          }
      })
      .catch((err) => {
        // console.log("Erreur: " + err)
      }) 
    }

    if (!isOpen) {
      fetchBugReports()
    }
  }, [isOpen]);

  return (
    <DashboardLayout>
        <Flex flexDirection='row'>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Rapporter un bug ou effectuer une demande d'amélioration mobile/web</Text>
        </Flex>
        <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette page vous permet de rapporter la présence d'un bug (esthétique ou technique) ou d'effectuer une demande d'amélioration informatique pour l'application web, l'API ou une des applications mobiles</Text>
        <Divider mt={5} mb={5}/>
        <Flex p={5} mb={5} flexDirection='column' style={{borderRadius: 10, cursor: 'pointer', color: 'white', backgroundColor: COLORS.pharmGreen }} _hover={{backgroundColor: COLORS.pharmGreenHover}} userSelect="none" onClick={onOpen}>
          <Icon style={{alignSelf:'center'}} path={mdiPlusBoxOutline} color="white" size={1.2}/>
          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Déclarer un bug/demande d'amélioration</Text>
        </Flex>
        <Table variant='simple'>
          <TableCaption>Rapports de bugs ou demandes d'améliorations publiées</TableCaption>
          <Thead>
            <Tr>
              <Th>Localisation</Th>
              <Th>Sévérité</Th>
              <Th>Nom</Th>
              <Th>Description</Th>
              <Th>Complété</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bugs.length >= 1 &&
              bugs.map((bug, index) => {
                const getSeverityColor = (severity) => {
                  switch(severity) {
                    case 0: return COLORS.pharmGreen
                    case 1: return COLORS.yellow
                    case 2: return COLORS.pharmRed
                    default: return null
                  }
                }

                const getCompletionJSX = (buglevel) => {
                  switch(buglevel) {
                    case 0: return <></>
                    case 1: return <><Icon style={{alignSelf:'center', margin:'auto', color: 'black'}} path={mdiTimerSand} size={1.2}/><Text userSelect='none' textAlign='center' style={{...TEXT.small, color: 'black'}}>En cours</Text></>
                    case 2: return <><Icon style={{alignSelf:'center', margin:'auto', color: COLORS.pharmRed}} path={mdiClose} size={1.2}/><Text userSelect='none' textAlign='center' style={{...TEXT.small, color: COLORS.pharmRed}}>Annulé</Text></>
                    case 3: return <><Icon style={{alignSelf:'center', margin:'auto', color: COLORS.pharmGreen}} path={mdiCheck} size={1.2}/><Text userSelect='none' textAlign='center' style={{...TEXT.small, color: COLORS.pharmGreen}}>Complété</Text></>
                    default: return null
                  }
                }

                return (
                  <Tr key={index}>
                    <Td justifyContent='center'>{bug.bug_location === 0 ? <Icon style={{alignSelf:'center', margin: 'auto'}} path={mdiWeb} size={1.2}/> : <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiCellphone} size={1.2}/>}</Td>
                    <Td backgroundColor={getSeverityColor(bug.bug_severity)}></Td>
                    <Td>{bug.bug_name}</Td>
                    <Td fontSize='xs'>{bug.bug_desc}</Td>
                    <Td>{getCompletionJSX(bug.bug_done)}</Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
        <BugsModal isOpen={isOpen} onClose={onClose}/>
    </DashboardLayout>
  );
}
