import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormHelperText,
    FormLabel,
    Select,
    Textarea
} from '@chakra-ui/react'
import { COLORS } from '../styles/global';
import axios from 'axios';

export default function BugsModal({isOpen, onClose}) {
    const [bugname, setbugname] = useState("");
    const [bugdesc, setbugdesc] = useState("");
    const [buglocation, setbuglocation] = useState(0);
    const [bugseverity, setbugseverity] = useState(0);
    const [isdisabled, setisdisabled] = useState(true);

    const handleSubmit = async () => {
        setisdisabled(true)
        await axios({
            method: "POST",
            data: {
                bugname,
                bugdesc,
                buglocation,
                bugseverity
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'bugs'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            }
        })
        .then((bug) => {
            // console.log("Created bugreport")
            setisdisabled(false)
            setbuglocation(0)
            setbugseverity(0)
            onClose()
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    return  (
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Déclarer un bug/demande d'amélioration</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl id="bugname" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Titre du bug/demande d'amélioration</FormLabel>
                        <Input type="text" min={0} max={128} onChange={(val) => {setbugname(val.target.value); setisdisabled(false)}}/>
                        <FormHelperText>{bugname.length}/128 caractères</FormHelperText>
                    </FormControl>
                    <FormLabel color={COLORS.pharmBlue}>Application ciblée</FormLabel>
                    <Select mb={3} onChange={(val) => setbuglocation(val.target.value)}>
                        <option value={0}>Web/API</option>
                        <option value={1}>Application mobile</option>
                    </Select>
                    <FormLabel color={COLORS.pharmBlue}>Niveau d'urgence/sévérité</FormLabel>
                    <Select mb={3} onChange={(val) => setbugseverity(val.target.value)}>
                        <option value={0} style={{backgroundColor: COLORS.pharmGreen}}>Non-urgent</option>
                        <option value={1} style={{backgroundColor: COLORS.yellow}}>Urgence modérée</option>
                        <option value={2} style={{backgroundColor: COLORS.pharmRed}}>Urgence prioritaire</option>
                    </Select>
                    <FormControl id="bugdesc" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Description de la demande/étapes pour reproduire</FormLabel>
                        <Textarea type="text" min={0} max={1000} onChange={(val) => setbugdesc(val.target.value)}/>
                        <FormHelperText>{bugdesc.length}/1000 caractères</FormHelperText>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onClose}>
                    Fermer
                </Button>
                <Button backgroundColor={COLORS.pharmGreen} color="#eee" disabled={isdisabled} onClick={() => handleSubmit()}>Soumettre</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
