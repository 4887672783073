import React from 'react'
import BounceLoader from "react-spinners/BounceLoader"
import { COLORS } from './styles/global'

const loaderCss = {
    fontWeight: "bold",
    marginLeft: 15,
    marginTop:10,
    left: "50%", 
}

export default function Loading() {
    return (
        <BounceLoader loading color={COLORS.pharmBlue} size={60} css={loaderCss}/>
    )
}
