import { Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { COLORS, TEXT } from '../../styles/global'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiAccountSearchOutline, mdiPlaylistPlus, mdiMedalOutline, mdiMessageBulleted, mdiCogOutline } from '@mdi/js';
import axios from 'axios'

import NoteEditor from './NoteEditor'

export default function StudentEntry({student, isFromList, listid, setsearchError}) {
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();
    const [showEditor, setshowEditor] = useState(false)

    const addToList = async () => {
        await axios({
            method: "POST",
            data: {
                listid: listid
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/list/' + student.uid
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setsearchError("L'étudiant " + student.prenom + " " + student.nom + " a été ajouté à la liste!")
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    const removeUidList = async () => {
        await axios({
            method: "DELETE",
            data: {
                listid: listid
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/list/' + student.uid
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            }

            navigate(0)
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })

    }

    return (
        <Flex flexDirection='column' p={5} style={{borderWidth: 1, borderRadius: 10}} userSelect="none" _hover={{borderColor: COLORS.pharmBlueHover}}>
            <Flex flexDirection='row'>
                <Flex flexDirection='column' flex={0.5} justifyContent='center'>
                    <Text style={{...TEXT.bold, ...TEXT.h3}}>{student.prenom} {student.nom}</Text>
                    <Text>Cohorte {student.cohorte}</Text>
                </Flex>
                <Flex flexDirection='row' flex={0.5}>
                    <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/student/stats?uid=' + student.uid)}>
                        <Icon style={{alignSelf:'center'}} path={mdiAccountSearchOutline} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Statistiques</Text>
                    </Flex>
                    {isFromList ?
                        <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => removeUidList()}>
                            <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Retirer de la liste</Text>
                        </Flex>

                    : 
                        searchParams.get('from') === '/student/list' && searchParams.get('listid') !== null ?
                            <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => addToList()}>
                                <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                                <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Ajouter à la liste</Text>
                            </Flex>
                        :
                            <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/student/list?uid=' + student.uid)}>
                                <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                                <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Ajout à une liste</Text>
                            </Flex>
                    }
                    <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => navigate('/student/stats?uid=' + student.uid + '&badges=1')}>
                        <Icon style={{alignSelf:'center'}} path={mdiMedalOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Offrir un badge</Text>
                    </Flex>
                    <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => navigate('/manageusers?search=' + student.uid)}>
                        <Icon style={{alignSelf:'center'}} path={mdiCogOutline} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Configuration</Text>
                    </Flex>
                    <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: "#333", cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: "#333"}} onClick={() => setshowEditor(!showEditor)}>
                        <Icon style={{alignSelf:'center'}} path={mdiMessageBulleted} color="#333" _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Lire ou éditer notes</Text>
                    </Flex>
                </Flex>
            </Flex>
            {showEditor &&
                <NoteEditor uid={student.uid}/>
            }
        </Flex>
    )
}
