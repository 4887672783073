import React, { useContext, useEffect } from 'react'
import { Text, Divider, Flex, Heading, Stack, Image} from '@chakra-ui/react'
import { COLORS, IMAGES } from '../components/styles/global'
import { useNavigate, useLocation } from 'react-router-dom'

import DefaultLayout from '../components/layouts/DefaultLayout'
import { AuthContext } from '../components/context/AuthContext'
import svgBackground from '../components/styles/background.svg'

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginScreen() {
    const { isAuthenticated } = useContext(AuthContext)
    let query = useQuery();
    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/")
        }
    }, [isAuthenticated, navigate])

    return (
        <Flex direction="row" flexWrap="wrap" justifyContent="center" height="100vh" width="100vw" style={{backgroundImage: `url(${svgBackground})`, backgroundSize: 'cover'}}>
            <DefaultLayout bgColor="white">
                <Stack spacing={2} color={COLORS.pharmBlue} textAlign="center">
                    <Image src={IMAGES.icon} width={150} height={150} alignSelf="center"/>
                    <Heading fontSize={36}>Créer un compte</Heading>
                    <Divider></Divider>
                    <Text>Vous pouvez terminer la création de votre compte Ma Résidence sur cette page. Veuillez vous assurer que toutes les informations soient adéquates - si cela n'est pas le cas, veuillez contacter le support technique de la faculté pour effectuer les corrections à la fin de l'enregistrement.</Text>
                    <Divider></Divider>
                    <Text>Vous serez enregistrés au nom de: </Text>
                    <Text fontWeight='bold'>{ query.get('s').toUpperCase() + " " + query.get('n').toUpperCase() }</Text>
                    <Text>Votre statut d'usager sera: </Text>
                    <Text fontWeight='bold'>ÉTUDIANT</Text>
                </Stack>
            </DefaultLayout>
        </Flex>
    )
}
