import React, { useState, useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import { htmlEncode, htmlDecode } from 'js-htmlencode'
import { TEXT, COLORS } from '../../styles/global';
import axios from 'axios';

export default function NoteEditor({uid}) {
    const [editorTimeout, seteditorTimeout] = useState(0)
    const [editorState, seteditorState] = useState(EditorState.createEmpty())
    const [lastRecordedState, setlastRecordedState] = useState("")
    const [savedText, setsavedText] = useState("")

    useEffect(() => {
        const getUserNote = async() => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + uid + '/note'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    const newNote = htmlDecode(res.data['note'])
                    setlastRecordedState(newNote)
                    const contentBlock = htmlToDraft(newNote);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                        seteditorState(EditorState.createWithContent(contentState))
                    }
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })             
        }

        if (uid !== null) {
            getUserNote()
        }
    }, [uid])

    useEffect(() => {
        const registerNote = async (getContext) => {
            setlastRecordedState(getContext)
            await axios({
                method: "POST",
                data: {
                    note: htmlEncode(getContext)
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + uid + '/note'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setsavedText("✓ La note a été enregistrée!")
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }

        if (savedText !== "") {
            setsavedText("")
        }
        if(editorTimeout) clearTimeout(editorTimeout);
        seteditorTimeout(setTimeout(() => {
            let getContext = stateToHTML(editorState.getCurrentContent())
            if (lastRecordedState !== getContext && getContext !== '<p><br></p>') {
                registerNote(getContext)
            }
        }, 3000));
    }, [editorState])

    return (
        <Flex flexDirection='column' width="100%">
            <Editor 
                editorState={editorState}
                toolbarClassName="editToolbar"
                wrapperClassName="editWrapper"
                editorClassName="editEditor"
                editorStyle={{ border: "1px solid #ddd", marginBottom: 5, paddingBottom: 5, height: 150, lineHeight: "50%", minWidth: 700 }}
                onEditorStateChange={(state) => seteditorState(state)}
                toolbar={{
                    options: ['inline', 'list', 'remove', 'history']
                }}
            />
            {savedText === "" ?
                <Text mt={2} style={{...TEXT.vsmall, ...TEXT.bold, color: '#aaa', marginLeft: 5, userSelect: 'none'}}>Veuillez patienter quelques secondes à la fin de la saisie pour l'enregistrement automatique</Text>
            :
                <Text mt={2} style={{...TEXT.small, ...TEXT.bold, color: COLORS.pharmGreen, marginLeft: 5, userSelect: 'none'}}>{savedText}</Text>
            }
        </Flex>
    )
}
