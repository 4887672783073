import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react'
import { TEXT, COLORS } from '../../styles/global'
import QRCode from "react-qr-code";

export default function QrCodeModal({userUid, isOpen, onClose}) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent>
            <ModalHeader style={{color: COLORS.pharmBlue}}>Code QR de l'étudiant</ModalHeader>
            <ModalCloseButton />
            <ModalBody justifyContent='center'>
                <Text style={{...TEXT.regular, color: COLORS.pharmBlue}} pb={5}>Vous pouvez utiliser ce code dans l'application pour lier un compte maître de stage à l'étudiant</Text>
                <QRCode size={150} style={{margin:'auto'}} value={userUid}/>
                <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.regular, color: COLORS.pharmBlue}} pt={2}>{userUid}</Text>
            </ModalBody>
            <ModalFooter>
                <Button variant="ghost" style={{color: COLORS.pharmBlue}} onClick={onClose}>Fermer</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
