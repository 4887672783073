import React from 'react'
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts'
import { COLORS } from '../../styles/global';

export default function UserStats({chartData}) {
    return (
        <ResponsiveContainer width="100%" height={450}>
            <BarChart layout="vertical" stackOffset="expand" width={450} height={300} data={chartData} margin={{left:20}}>
                <XAxis type="number" allowDecimals={false}/>
                <YAxis type="category" title="Quantité d'utilisateurs" dataKey="name" width={80}/>
                <Tooltip/>
                <Bar dataKey="count" name="Compte" fill={COLORS.pharmGreen}/>
            </BarChart>
        </ResponsiveContainer>
    );
}
