import React, { useState, useRef, useEffect } from 'react'
import { Text, FormControl, Input, FormHelperText, FormLabel, Divider, Stack, Flex, Box } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Icon from '@mdi/react'
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from '@mdi/js';

import DashboardLayout from '../components/layouts/DashboardLayout'
import MasterEntry from '../components/stats/master/MasterEntry'

export default function MasterSearch() {
    let timeout;
    const [searchParams,] = useSearchParams();
    const navigate = useNavigate();
    const searchField = useRef()
    const [isLoading, setisLoading] = useState(false)
    const [foundUsers, setfoundUsers] = useState([])
    const [searchError, setsearchError] = useState(false)
    const [searchPage, setsearchPage] = useState(1)
    const [searchValue, setsearchValue] = useState("")

    useEffect(() => {
        const sendSearch = async () => {
            setisLoading(true)
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'masters/search/' + searchValue + '/' + searchPage
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setsearchError(false)
                    setfoundUsers(res.data)
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setsearchError("Aucun résultat trouvé pour la recherche: " + searchValue)
                    setfoundUsers([])
                } else {
                    setsearchError("Impossible de se connecter au serveur")
                    // console.log("Erreur: " + err)
                }
            }) 
            setisLoading(false)
            searchField.current.focus()
        }

        if (searchValue !== "") {
            sendSearch()
        }
    }, [searchValue, searchPage])

    useEffect(() => {
        // Redirections after specific searches
        if (foundUsers.length === 1) { 
            if (searchParams.get('from') === '/master/stats') {
                navigate('/master/stats?uid=' + foundUsers[0].uid)
                return
            }
        }
    }, [foundUsers, navigate, searchParams])

    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Recherche par maître de stage</Text>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette page vous permet de trouver un maître pour afficher ses statistiques à l'égard de ses évaluations</Text>
            <Divider mt={5}/>
            {searchParams.get('from') !== null &&
                <Box bg={COLORS.pharmGreen} color="#eee" width="100%" p={4} borderRadius={10} userSelect='none'>
                    <Text style={{...TEXT.bold}}>Vous avez été redirigé</Text>
                    <Text style={{...TEXT.regular}}>
                        La recherche ci-bas vous renverra automatiquement à la page demandée lorsqu'un seul résultat sera trouvé.
                    </Text>
                </Box>
            }
            <FormControl id="masterSearch" mt={5} color={COLORS.pharmBlue}>
                <FormLabel>Rechercher un maître de stage</FormLabel>
                <Input autoFocus ref={searchField} type="text" isDisabled={isLoading} onChange={(val) => { 
                    if(timeout) clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        setsearchPage(1)
                        setsearchValue(val.target.value)
                    }, 750);
                }}/>
                {searchError &&
                    <Text mt={3} style={{...TEXT.bold, ...TEXT.vsmall, color: COLORS.pharmRed}}>{searchError}</Text>
                }
                <FormHelperText mb={5}>Veuillez inscrire le nom, prénom ou identifiant unique. La recherche se lancera automatiquement à la fin de la saisie.</FormHelperText>
                <Stack direction="column" overflowY="auto" overflowX="hidden" maxHeight="55vh">
                {foundUsers.length > 0 &&
                    foundUsers.map((user, i) => {
                        return (
                            <MasterEntry key={i} master={user}/>
                        )
                    })
                }
                </Stack>
                {foundUsers.length > 0 && 
                    <Flex mt={3} width="100%" direction='row' justifyContent="center" alignItems="center" userSelect="none">
                        {searchPage > 1 &&
                            <Flex flexDirection='row' p={2} mr={5} borderWidth={1} borderRadius={10} cursor="pointer" _hover={{color: "#eee", backgroundColor: COLORS.pharmBlueHover}} onClick={() => setsearchPage(searchPage-1)}>
                                <Icon path={mdiArrowLeftCircleOutline} color={COLORS.pharmBlue} _hover={{color:"#eee"}} size={1}/>
                                <Text ml={3}>Résultats précédents</Text>
                            </Flex>
                        }
                        {foundUsers.length === 9 &&
                            <Flex flexDirection="row" p={2} borderWidth={1} borderRadius={10} cursor="pointer" _hover={{color: "#eee", backgroundColor: COLORS.pharmBlueHover}} onClick={() => setsearchPage(searchPage+1)}>
                                <Text mr={3}>Résultats suivants</Text>
                                <Icon path={mdiArrowRightCircleOutline} color={COLORS.pharmBlue} _hover={{color:"#eee"}} size={1}/>
                            </Flex>
                        }    
                    </Flex>
                }
            </FormControl>
        </DashboardLayout>
    )
}
