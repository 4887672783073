import FormError from "../FormError"
import axios from "axios"

export async function CheckIsAuthenticated() {
    return await axios({
        method: "POST",
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER + "token_login"
    }).then(res => {
        if (res.status === 200) {
            return {uid: res.data.uid, authLevel: res.data.auth_level}
        }
        throw new Error("Unknown error code")
    }).catch(err => {
        throw new Error("Not authenticated")
    })
}

// export async function authSignUp(loginPayload) {
//     if (loginPayload.uname === "") throw new FormError("Vous devez fournir un nom d'utilisateur", "username")
//     if (loginPayload.pw1 === "" || loginPayload.pw2 === "") throw new FormError("Vous devez fournir un mot de passe", "password")
//     if (loginPayload.pw1 !== loginPayload.pw2) throw new FormError("Les mots de passe ne concordent pas", "password")

//     await axios({
//         method: "POST",
//         data: {
//             username: loginPayload.uname,
//             password: loginPayload.pw1
//         },
//         withCredentials: true,
//         url: "/api/register"
//     })
//     .then((res) => {
//         if (res) return true
//     })
//     .catch((err) => {
//         if (err.response.status === 401) {
//             throw new FormError(`L'utilisateur '${loginPayload.uname}' existe déjà`, "username")
//         } else {
//             throw new FormError(`Impossible de se connecter au serveur. Veuillez réessayer plus tard.`)
//         }
//     });
// }

export async function authLogin(loginPayload) {
    if (!loginPayload.username) {
        throw new FormError("Vous devez fournir un nom d'utilisateur", "username");
    }

    return await axios({
        method: "POST",
        data: {
            username: loginPayload.username,
            password: loginPayload.pw
        },
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER + 'login'
    })
    .then(res => {
        if (![200, 401].includes(res.status)) {
            throw new FormError("Erreur serveur - veuillez réessayer plus tard", "all");
        }
        return res.data
    })
    .catch((err) => {
        //console.log(err.response)
        if (err.response.data.kind === '0x010') {
            // console.log("Unauthorized connection attempt by " + err.response.data.uid)
            throw new FormError("Vous n'êtes pas autorisés à consulter cette ressource. Votre tentative de connection sera rapportée à l'équipe de la faculté.", "all");
        }
        if (err.response.data.kind === '0x003') {
            throw new FormError("Votre mot de passe doit être modifié", "changepw", err.response.data.uid);
        }
        if (err.response.status === 401) {
            throw new FormError("Nom d'utilisateur ou mot de passe incorrect", "all");
        } else {
            throw new FormError("Erreur serveur - veuillez réessayer plus tard", "all");
        }
    })
}

export async function authSetPassword(errorMessage, password, passwordconfirm) {
    // Sets password for newly created username only. Does not implement security features for pw change afterwards
    // TODO should this really pass variables through the error handler ?
    if (password !== passwordconfirm) {
        throw new FormError("Les mots de passe ne concordent pas", "changepw", errorMessage.additionalData)
    }
    if (password.length <= 5) {
        throw new FormError("Le mot de passe choisi est trop court", "changepw", errorMessage.additionalData)
    }
    return await axios({
        method: "POST",
        data: {
            uid: errorMessage.additionalData,
            password
        },
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER + 'pw_change'
    })
    .then(res => {
        if (res.status !== 200) {
            throw new FormError("Erreur serveur - le mot de passe a peut-être déjà été attribué ou la connection a échoué", "all");
        }
    })
    .then(() => {
        throw new FormError("Le mot de passe a été changé avec succès", null);
    })
}
   
export function authLogout(userUid) {
    axios({
        method: "GET",
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER  + "disconnect/" + userUid
    })
}
