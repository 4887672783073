import React, { useContext } from 'react'
import { Stack, Text } from '@chakra-ui/react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { mdiLock } from '@mdi/js';
import Icon from '@mdi/react'
import { COLORS, TEXT } from '../styles/global'
import { AuthContext } from '../context/AuthContext'

export default function MenuItem({title, icon, link, minimalAuthLevel}) {
    const navigate = useNavigate()
    const location = useLocation()
    const { authLevel } = useContext(AuthContext)
    const hasMinimalAuthLevel = minimalAuthLevel === undefined || (minimalAuthLevel !== undefined && authLevel >= minimalAuthLevel)

    let urlOrigin = location.pathname
    const [searchParams,] = useSearchParams();
    if (searchParams.get('from') !== null) {
        urlOrigin = searchParams.get('from')
    }

    const getRedirect = (link) => {
        if (hasMinimalAuthLevel) navigate(link)
    }

    return (
        <Stack direction='row' alignItems='center' padding={2} width="100%" style={urlOrigin === link ? { borderRightWidth: 6, borderColor: COLORS.pharmGreen}:{ border:0 }} _hover={hasMinimalAuthLevel && {textDecoration: 'underline', cursor: 'pointer'}} onClick={() => getRedirect(link)}>
            <Icon path={hasMinimalAuthLevel ? icon : mdiLock} color={urlOrigin === link ? COLORS.pharmGreen : "#eee"} size={1}/>
            <Text style={hasMinimalAuthLevel ? {...TEXT.small, ...TEXT.regular}:{...TEXT.small, ...TEXT.regular, color: '#bbb'}} userSelect='none'>{title}</Text>
        </Stack>
    )
}
