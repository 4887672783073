import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { AuthContext } from './AuthContext';

const ApiContext = React.createContext()

const ApiProvider = (props) => {
    const [refresh, setrefresh] = useState(false)

    const { isAuthenticated } = useContext(AuthContext)
    const [apc, setapc] = useState([])
    const [niveau, setniveau] = useState([])
    const [badge, setbadge] = useState([])
    const [paramDates, setparamDates] = useState([])
    const [isloading, setisloading] = useState(true)

    const fetchApc = async () => {
        await axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'apc'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setapc(res.data)
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    const fetchNiveau = async () => {
        await axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'niveaux'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setniveau(res.data)
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    const fetchBadge = async () => {
        await axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'badges'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                //console.dir(res.data)
                setbadge(res.data)
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    const fetchParamDates = async () => {
        await axios({
            method: "POST",
            data: {
                paramtype: 'date'
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'params/get'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setparamDates(res.data)
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        }) 
    }

    const toggleRefresh = () => {
        setrefresh(!refresh)
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchApc()
            fetchNiveau()
            fetchBadge()
            fetchParamDates()
            setisloading(false)
        }
    }, [isAuthenticated, refresh])

    return (
        <ApiContext.Provider value={{
          apc,
          niveau,
          badge,
          paramDates,
          isloading,
          toggleRefresh,
          fetchApc,
          fetchNiveau
        }}>
          {props.children}
        </ApiContext.Provider>
      )
}

export { ApiContext, ApiProvider }