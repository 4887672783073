import React, { useContext } from 'react'
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Legend } from 'recharts'
import Loading from '../../Loading';
import { ApiContext } from '../../context/ApiContext';

export default function ApcStats({chartData}) {
    const Api = useContext(ApiContext)
    const { niveau, isloading } = Api

    return (
        isloading ?
            <Loading/>
        :
            <ResponsiveContainer width="100%" height={600}>
                <BarChart layout="vertical" width={450} height={600} data={chartData} margin={{left:20}}>
                    <XAxis type="number" allowDecimals={false}/>
                    <YAxis type="category" title="Quantité d'utilisateurs" dataKey="name" width={120}/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="niv_1" stackId="a" name={niveau[0].titre} fill={niveau[0].color}/>
                    <Bar dataKey="niv_2" stackId="a" name={niveau[1].titre} fill={niveau[1].color}/>
                    <Bar dataKey="niv_3" stackId="a" name={niveau[2].titre} fill={niveau[2].color}/>
                    <Bar dataKey="niv_4" stackId="a" name={niveau[3].titre} fill={niveau[3].color}/>
                    <Bar dataKey="niv_5" stackId="a" name={niveau[4].titre} fill={niveau[4].color}/>
                </BarChart>
            </ResponsiveContainer>
    );
}
