import React, { useContext } from "react"
import { Flex, Image, Text, Divider, Stack } from "@chakra-ui/react"
import { COLORS, IMAGES, TEXT } from '../styles/global'
import packageJson from '../../../package.json';
import { mdiChartBellCurve, mdiAccountGroup, mdiCog, mdiLogout, mdiChartPie, mdiAccountSearch, mdiAccountSearchOutline, mdiFolderAccount, mdiPlaylistPlus, mdiSchool, mdiBugOutline, mdiFolderStar, mdiAccount, mdiPoll, mdiDatabase, mdiApple, mdiAndroid, mdiAccountMultiplePlus } from '@mdi/js';
import Icon from '@mdi/react'
import { AuthContext } from "../context/AuthContext";
import MenuItem from "../Dashboard/MenuItem";

export default function DashboardLayout(props) {
  const { logout } = useContext(AuthContext)
  const doLogout = async () => {
    await logout()
  }
  return (
    <Flex flexDirection="row" minHeight="100vh">
        <Flex flex={0.2} flexDirection="column" minWidth={300} bgColor={COLORS.pharmBlue} color="#eee" alignItems='center'>
            <Image src={IMAGES.icon} boxSize={125} marginTop={10}/>
            <Text style={{...TEXT.centered, ...TEXT.h2}} userSelect='none'>Ma résidence</Text>
            <Text style={{...TEXT.centered, ...TEXT.vsmall, marginBottom: 5}}>Version {packageJson.version}</Text>
            <Divider width="80%"/>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2}>
              <Icon path={mdiChartBellCurve} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Analytique</Text>
            </Stack>
            <MenuItem title="Statistiques par cohorte" icon={mdiChartPie} link="/cohortestats"/>
            <MenuItem title="Statistiques par étudiant" icon={mdiFolderAccount} link="/student/stats"/>
            <MenuItem title="Statistiques par maître de stage" icon={mdiFolderStar} link="/master/stats"/>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2}>
              <Icon path={mdiAccountGroup} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Usagers</Text>
            </Stack>
            <MenuItem title="Rechercher - étudiant" icon={mdiAccountSearch} link="/student/search"/>
            <MenuItem title="Rechercher - maître de stage" icon={mdiAccountSearchOutline} link="/master/search"/>
            <MenuItem title="Listes d'étudiants" icon={mdiPlaylistPlus} link="/student/list"/>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2}>
              <Icon path={mdiCog} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Administration</Text>
            </Stack>
            <MenuItem title="Bugs & améliorations" icon={mdiBugOutline} link="/bugs"/>
            <MenuItem title="Gérer les utilisateurs" icon={mdiAccount} link="/manageusers" minimalAuthLevel={3}/>
            <MenuItem title="Gérer les APCs/niveaux" icon={mdiPoll} link="/managedata" minimalAuthLevel={3}/>
            <MenuItem title="Configurer les stages" icon={mdiSchool} link="/stages" minimalAuthLevel={3}/>
            <MenuItem title="Importer une liste d'utilisateurs" icon={mdiAccountMultiplePlus} link="/userimport" minimalAuthLevel={3}/>
            <MenuItem title="Générer des utilisateurs fictifs" icon={mdiAccountGroup} link="/populate" minimalAuthLevel={3}/>
            <MenuItem title="Exporter la base de données" icon={mdiDatabase} link="/databaseexport" minimalAuthLevel={3}/>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2} cursor='pointer' _hover={{backgroundColor: COLORS.pharmGreen, color: '#eee'}} onClick={() => window.location.assign(packageJson.iosAppLocation)}>
              <Icon path={mdiApple} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Obtenir l'application</Text>
            </Stack>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2} cursor='pointer' _hover={{backgroundColor: COLORS.pharmGreen, color: '#eee'}} onClick={() => window.location.assign(packageJson.androidAppLocation)}>
              <Icon path={mdiAndroid} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Obtenir l'application</Text>
            </Stack>
            <Stack direction='row' alignItems='center' borderWidth={1} borderRadius={10} padding={2} marginTop={2} cursor='pointer' _hover={{backgroundColor: COLORS.pharmRed, color: '#eee'}} onClick={() => doLogout()}>
              <Icon path={mdiLogout} color="#eee" size={1}/>
              <Text style={{...TEXT.h3, ...TEXT.bold}} userSelect='none'>Déconnexion</Text>
            </Stack>

        </Flex>
        <Flex flex={0.8} direction="column" minHeight="100vh" padding={10}>
            {props.children}
        </Flex>
    </Flex>
  )
}

DashboardLayout.defaultProps = {
  bgColor: "rgb(235, 248, 255)"
}