import React, { useContext, useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Divider } from '@chakra-ui/react'
import { ApiContext } from '../../context/ApiContext'
import { TEXT, COLORS } from '../../styles/global'
import axios from 'axios'

export default function BadgeModal({isOpen, onClose, badgeId, student, triggerReload, settriggerReload}) {
    const Api = useContext(ApiContext)
    const { badge } = Api
    const [isloading, setisloading] = useState(false)

    const handleBadgeOffer = async () => {
        setisloading(true)
        await axios({
            method: "POST",
            data: {
                notifications_token: student.notifications_token
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'users/' + student.uid + '/badges/' + (badgeId+1)
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setisloading(false)
                settriggerReload(!triggerReload)
                onClose()
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        }) 
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent>
            <ModalHeader style={{color: COLORS.pharmBlue}}>Offrir un badge</ModalHeader>
            {!isloading &&
                <ModalCloseButton />
            }
            <ModalBody>
                <Text style={{...TEXT.small, color: COLORS.pharmBlue}}>Vous aller offrir le badge:</Text>
                <Text style={{...TEXT.small, ...TEXT.bold, color: COLORS.pharmBlue}}>{badge[badgeId] !== undefined ? badge[badgeId].titre : "Loading..."}</Text>
                <Text style={{...TEXT.small, color: COLORS.pharmBlue}}>À {student.prenom + " " + student.nom}</Text>
                <Divider mt={3} mb={3}/>
                <Text style={{...TEXT.small, color: COLORS.pharmBlue}}>Cette action notifiera l'étudiant sur son application mobile et est irréversible</Text>
            </ModalBody>

            <ModalFooter>
                <Button variant="ghost" style={{color: COLORS.pharmBlue}} isDisabled={isloading} onClick={onClose}>Annuler</Button>
                <Button colorScheme="blue" isDisabled={isloading} mr={3} onClick={handleBadgeOffer}>
                    Offrir le badge
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
