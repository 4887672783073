import React, { useRef, useContext, useState } from 'react'
import { Stack, FormControl, FormLabel, Input, Divider, Text, Flex, Button, Box} from "@chakra-ui/react"
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Loading from '../Loading'
import { COLORS } from '../styles/global';

export default function LoginForm() {
    const [username, setusername] = useState("");
    const password = useRef();
    const [newpassword, setnewpassword] = useState("");
    const confirmpassword = useRef();
    let navigate = useNavigate();
    const { login, isLoading, errorMessage, setPassword } = useContext(AuthContext)

    const Login = async (e) => {
        e.preventDefault()

        if (errorMessage.errorField === 'changepw') {
            if (newpassword === "" || confirmpassword.current.value === "") return
            await setPassword(newpassword, confirmpassword.current.value)
            setusername("")
        } else {
            let pw = password.current.value
            setnewpassword("")

            await login({username, pw}).then((isAuth) => {
                if (isAuth) {
                    navigate("/")
                }
            })
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            Login(e)
        }
    }

    return (
        <React.Fragment>
            <Flex direction="row">
                {isLoading &&
                    <Loading/>
                }
            </Flex>
            <Divider />
            <Box spacing={4} opacity={isLoading ? 0.5 : 1}>
                <Stack spacing={4} color={COLORS.pharmBlue}>
                    {errorMessage.errorField === 'changepw' ?
                        <form>
                            <FormControl isDisabled={isLoading} id="newpassword" marginBottom={5}>
                                <FormLabel>Mot de passe</FormLabel>
                                <Input type="password" value={newpassword} onChange={(val) => setnewpassword(val.target.value)} onKeyDown={(e) => handleKeyDown(e)}/>
                            </FormControl>
                            <FormControl isDisabled={isLoading} id="confirmpassword">
                                <FormLabel>Confirmer le mot de passe</FormLabel>
                                <Input ref={confirmpassword} type="password" onKeyDown={(e) => handleKeyDown(e)}/>
                            </FormControl>
                        </form>
                    :
                        <form>
                            <FormControl isDisabled={isLoading} id="username" marginBottom={5} isInvalid={["username", "all"].includes(errorMessage.errorField)}>
                                <FormLabel>Nom d'utilisateur</FormLabel>
                                <Input value={username} onChange={(val) => setusername(val.target.value)} autoComplete="username" type="text" onKeyDown={(e) => handleKeyDown(e)}/>
                            </FormControl>
                            <FormControl isDisabled={isLoading} id="password" isInvalid={["password", "all"].includes(errorMessage.errorField)}>
                                <FormLabel>Mot de passe</FormLabel>
                                <Input ref={password} autoComplete="current-password" type="password" onKeyDown={(e) => handleKeyDown(e)}/>
                            </FormControl>
                        </form>
                    }
                    {errorMessage.message !== "" &&
                        <Text color={COLORS.pharmBlue} fontWeight="bold">{errorMessage.message}</Text>
                    }
                    <Button isDisabled={isLoading} bg="blue.50" color="blue.500" onClick={(e) => Login(e)}>{errorMessage.errorField !== 'changepw' ? 'Se connecter' : 'Changer le mot de passe'}</Button>
                </Stack>
            </Box>
        </React.Fragment>
    )
}
