import React, { useEffect, useState, useRef } from 'react';
import { 
  Flex, 
  Text, 
  Divider, 
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  FormControl,
  FormLabel,
  Input,
  FormHelperText
} from '@chakra-ui/react';
import { mdiAccountEdit, mdiAccountLockOpen, mdiAccountRemove, mdiAccountPlus, mdiLockOpenAlert, mdiLockCheck, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import Icon from '@mdi/react'
import QRCode from "react-qr-code";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom'

import { COLORS, TEXT } from '../components/styles/global';
import DashboardLayout from '../components/layouts/DashboardLayout';
import CreateUserModal from '../components/manageusers/CreateUserModal';
import Loading from '../components/Loading';
import DeleteUserModal from '../components/manageusers/DeleteUserModal';
import ResetPasswordModal from '../components/manageusers/ResetPasswordModal';

export default function ManageUsers() {
  let timeout;
  const [searchParams, setsearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure()
  const { isOpen: isOpenReset, onOpen: onOpenReset, onClose: onCloseReset } = useDisclosure()
  const searchField = useRef()
  const [searchError, setsearchError] = useState(false)
  const [searchValue, setsearchValue] = useState("")
  const [foundUsers, setfoundUsers] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [adminUsers, setadminUsers] = useState([]);
  const [juryUsers, setjuryUsers] = useState([]);
  const [userData, setuserData] = useState(null);
  const [userUidDelete, setuserUidDelete] = useState(null);
  const [userUidReset, setuserUidReset] = useState(null);

  const doSearch = (searchValue) => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setsearchValue(searchValue)
    }, 750);
  }

  const sendSearch = async () => {
    setisLoading(true)
    await axios({
        method: "GET",
        withCredentials: true,
        url: process.env.REACT_APP_API_SERVER + 'users/auth/search/' + searchValue
    })
    .then(res => {
        if (![200, 401].includes(res.status)) {
            // console.log("Erreur serveur")
        } else {
            setsearchError(false)
            setfoundUsers(res.data)
        }
    })
    .catch((err) => {
        if (err.response.status === 404) {
            setsearchError("Aucun résultat trouvé pour la recherche: " + searchValue)
            setfoundUsers([])
        } else {
            setsearchError("Impossible de se connecter au serveur")
            // console.log("Erreur: " + err)
        }
    })
    .finally(() => {
        setisLoading(false)
        if (searchField.current !== null) {
            searchField.current.focus()
        }
    })
  }

  useEffect(() => {
    const sendSearchEffect = async () => {
      setisLoading(true)
      await axios({
          method: "GET",
          withCredentials: true,
          url: process.env.REACT_APP_API_SERVER + 'users/auth/search/' + searchValue
      })
      .then(res => {
          if (![200, 401].includes(res.status)) {
              // console.log("Erreur serveur")
          } else {
              setsearchError(false)
              setfoundUsers(res.data)
              setsearchParams({ search: searchValue })
          }
      })
      .catch((err) => {
          if (err.response.status === 404) {
              setsearchError("Aucun résultat trouvé pour la recherche: " + searchValue)
              setfoundUsers([])
          } else {
              setsearchError("Impossible de se connecter au serveur")
              // console.log("Erreur: " + err)
          }
      })
      .finally(() => {
          setisLoading(false)
          if (searchField.current !== null) {
              searchField.current.focus()
          }
      })
    }

    if (searchValue !== "") {
        sendSearchEffect()
    }
  }, [searchValue, searchParams])

  useEffect(() => {
    if (searchParams.get('search') !== null) {
      doSearch(searchParams.get('search'))
    }
  }, [searchParams]);

  const changeUserVisibility = async (uid, state) => {
    await axios({
      method: "POST",
      withCredentials: true,
      data: {
        visible: state
      },
      url: process.env.REACT_APP_API_SERVER + 'users/mask/' + uid
    })
    .then(res => {
      if (![200, 401].includes(res.status)) {
        // console.log("Erreur serveur")
        return
      }
      fetchUsers(3)
      fetchUsers(2)
      if (searchValue !== "") sendSearch()
    })
    .catch((err) => {
      // console.log("Erreur: " + err)
    })
    .finally(() => {
        setisLoading(false)
    })    
  }

  const fetchUsers = async (level) => {
    await axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_API_SERVER + 'users/auth/' + level
    })
    .then(res => {
      if (![200, 401].includes(res.status)) {
        // console.log("Erreur serveur")
      } else {
        if (level === 2) setjuryUsers(res.data)
        if (level === 3) setadminUsers(res.data)
      }
    })
    .catch((err) => {
      // console.log("Erreur: " + err)
      if (level === 2) setjuryUsers([])
      if (level === 3) setadminUsers([])
    })
    .finally(() => {
        setisLoading(false)
    })
  }

  useEffect(() => {
    if (!isOpen && !isOpenDelete && !isOpenReset) {
      setuserData(null)
      setuserUidDelete(null)
      setuserUidReset(null)
      fetchUsers(3)
      fetchUsers(2)
      if (searchValue !== "") sendSearch()
    }
  }, [isOpen, isOpenDelete, isOpenReset]);
  
  return (
    <DashboardLayout>
        <Flex flexDirection='row'>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Gestion des utilisateurs</Text>
        </Flex>
        <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette section vous permet de créer des nouveaux utilisateurs jury ou administrateurs de manière sécurisée et d'effectuer des tâches de maintenance sur les utilisateurs existants</Text>
        <Divider mt={5} mb={5}/>
        <Flex p={5} flexDirection='column' style={{borderRadius: 10, cursor: 'pointer', color: 'white', backgroundColor: COLORS.pharmGreen }} _hover={{backgroundColor: COLORS.pharmGreenHover}} userSelect="none" onClick={onOpen}>
          <Icon style={{alignSelf:'center'}} path={mdiAccountPlus} color="white" size={1.2}/>
          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Créer un utilisateur</Text>
        </Flex>
        <Divider mt={5} mb={5}/>
        <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Étudiants</Text>
        <FormControl id="studentSearch" mt={5} color={COLORS.pharmBlue}>
            <FormLabel>Rechercher un étudiant ou un maître de stage</FormLabel>
            <Input autoFocus ref={searchField} type="text" defaultValue={searchParams.get('search') === null ? "" : searchParams.get('search')} isDisabled={isLoading} onChange={(val) => doSearch(val.target.value)}/>
            <FormHelperText mb={5}>Veuillez inscrire le nom, prénom ou identifiant unique. La recherche se lancera automatiquement à la fin de la saisie.</FormHelperText>
        </FormControl>
        {searchError &&
            <Text mt={3} style={{...TEXT.bold, ...TEXT.vsmall, color: COLORS.pharmRed}}>{searchError}</Text>
        }
        {isLoading && <Loading/>}
        {foundUsers.length >= 1 &&
          <Table variant='simple' mb={5}>
            <TableCaption>Utilisateurs ayant des droits de niveau Étudiant ou Maître de stage</TableCaption>
            <Thead>
              <Tr>
                <Th textAlign='center'>Code UUID</Th>
                <Th textAlign='center'>Mot de passe</Th>
                <Th>Nom de l'utilisateur (cohorte)</Th>
                <Th>Courriel</Th>
                <Th textAlign='center'>Fonctions</Th>
              </Tr>
            </Thead>
            <Tbody>
                {foundUsers.map((user, index) => {
                  return (
                    <Tr key={index}>
                      <Td justifyContent='center'><QRCode size={100} style={{margin:'auto'}} value={user.uid}/><Text fontSize={8} textAlign='center'>{user.uid}</Text></Td>
                      <Td justifyContent='center'>{parseInt(user.has_password) === 0 ? <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockOpenAlert} color={COLORS.pharmRed} size={1.4}/> : <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockCheck} color='black' size={1.2}/>}</Td>
                      <Td>
                        <Text style={{...TEXT.bold}}>{user.prenom + " " + user.nom} {parseInt(user.auth_level) === 0 ? " (Cohorte " + user.cohorte + ")" : " (Maître de stage)"}</Text>
                        <Text style={{...TEXT.vsmall}}>Créé le {user.date_created}</Text>
                        <Text style={{...TEXT.vsmall}}>Dernière modif. le {user.date_modified}</Text>
                      </Td>
                      <Td>{user.courriel}</Td>
                      <Td>
                        <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.yellow, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.yellow}} onClick={() => {setuserData(user); onOpen()}}>
                          <Icon style={{alignSelf:'center'}} path={mdiAccountEdit} color={COLORS.yellow} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Modifier</Text>
                        </Flex>
                        { user.has_password === 1 &&
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidReset(user.uid); onOpenReset()}}>
                            <Icon style={{alignSelf:'center'}} path={mdiAccountLockOpen} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Réinitialiser mot de passe</Text>
                          </Flex>
                        }
                        {user.visible === 1 ?
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 0)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOffOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Masquer l'utilisateur</Text>
                          </Flex>
                        :
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 1)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Afficher l'utilisateur</Text>
                          </Flex>                        
                        }
                        <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidDelete(user.uid); onOpenDelete()}}>
                          <Icon style={{alignSelf:'center'}} path={mdiAccountRemove} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Supprimer</Text>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
            </Tbody>
          </Table>
        }

        <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Administrateurs</Text>
        {isLoading && <Loading/>}
        {adminUsers.length >= 1 ?
          <Table variant='simple' mb={5}>
            <TableCaption>Utilisateurs ayant des droits de niveau Administrateur</TableCaption>
            <Thead>
              <Tr>
                <Th textAlign='center'>Code UUID</Th>
                <Th textAlign='center'>Mot de passe</Th>
                <Th>Nom de l'utilisateur</Th>
                <Th>Courriel</Th>
                <Th textAlign='center'>Fonctions</Th>
              </Tr>
            </Thead>
            <Tbody>
                {adminUsers.map((user, index) => {
                  return (
                    <Tr key={index}>
                      <Td justifyContent='center'><QRCode size={100} style={{margin:'auto'}} value={user.uid}/><Text fontSize={8} textAlign='center'>{user.uid}</Text></Td>
                      <Td justifyContent='center'>{parseInt(user.has_password) === 0 ? <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockOpenAlert} color={COLORS.pharmRed} size={1.4}/> : <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockCheck} color='black' size={1.2}/>}</Td>
                      <Td>
                        <Text style={{...TEXT.bold}}>{user.prenom + " " + user.nom}</Text>
                        <Text style={{...TEXT.vsmall}}>Créé le {user.date_created}</Text>
                        <Text style={{...TEXT.vsmall}}>Dernière modif. le {user.date_modified}</Text>
                      </Td>
                      <Td>{user.courriel}</Td>
                      <Td>
                        <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.yellow, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.yellow}} onClick={() => {setuserData(user); onOpen()}}>
                          <Icon style={{alignSelf:'center'}} path={mdiAccountEdit} color={COLORS.yellow} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Modifier</Text>
                        </Flex>
                        { user.has_password === 1 &&
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidReset(user.uid); onOpenReset()}}>
                            <Icon style={{alignSelf:'center'}} path={mdiAccountLockOpen} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Réinitialiser mot de passe</Text>
                          </Flex>
                        }
                        {user.visible === 1 ?
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 0)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOffOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Masquer l'utilisateur</Text>
                          </Flex>
                        :
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 1)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Afficher l'utilisateur</Text>
                          </Flex>                        
                        }
                        <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidDelete(user.uid); onOpenDelete()}}>
                          <Icon style={{alignSelf:'center'}} path={mdiAccountRemove} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Supprimer</Text>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
            </Tbody>
          </Table>
        :
          <Text style={{...TEXT.vsmall, ...TEXT.centered, color: COLORS.pharmBlue}}>Aucun utilisateur administrateur!</Text>
        }

        <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Membres du jury</Text>
        {isLoading && <Loading/>}
        {juryUsers.length >= 1 ?
          <Table variant='simple'>
            <TableCaption>Utilisateurs ayant des droits de niveau Membres du jury</TableCaption>
            <Thead>
              <Tr>
                <Th textAlign='center'>Code UUID</Th>
                <Th textAlign='center'>Mot de passe</Th>
                <Th>Nom de l'utilisateur</Th>
                <Th>Courriel</Th>
                <Th textAlign='center'>Fonctions</Th>
              </Tr>
            </Thead>
            <Tbody>
                {juryUsers.map((user, index) => {
                  return (
                    <Tr key={index}>
                      <Td justifyContent='center'><QRCode size={100} style={{margin:'auto'}} value={user.uid}/><Text textAlign='center' fontSize={8}>{user.uid}</Text></Td>
                      <Td justifyContent='center'>{parseInt(user.has_password) === 0 ? <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockOpenAlert} color={COLORS.pharmRed} size={1.4}/> : <Icon style={{alignSelf:'center', margin:'auto'}} path={mdiLockCheck} color='black' size={1.2}/>}</Td>
                      <Td>
                        <Text style={{...TEXT.bold}}>{user.prenom + " " + user.nom}</Text>
                        <Text style={{...TEXT.vsmall}}>Créé le {user.date_created}</Text>
                        <Text style={{...TEXT.vsmall}}>Dernière modif. le {user.date_modified}</Text>
                      </Td>
                      <Td>{user.courriel}</Td>
                      <Td>
                        <Flex p={5} flex={0.33} flexDirection='column' 
                         style={{borderRadius: 10, borderColor: COLORS.yellow, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} 
                         justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.yellow}} 
                         onClick={() => {setuserData(user); onOpen()}}
                        >
                          <Icon style={{alignSelf:'center'}} path={mdiAccountEdit} color={COLORS.yellow} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Modifier</Text>
                        </Flex>
                        {user.has_password === 1 &&
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidReset(user.uid); onOpenReset()}}>
                            <Icon style={{alignSelf:'center'}} path={mdiAccountLockOpen} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Réinitialiser mot de passe</Text>
                          </Flex>
                        }
                        {user.visible === 1 ?
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 0)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOffOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Masquer l'utilisateur</Text>
                          </Flex>
                        :
                          <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmGreen, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmGreen}} onClick={() => {changeUserVisibility(user.uid, 1)}}>
                            <Icon style={{alignSelf:'center'}} path={mdiEyeOutline} color={COLORS.pharmGreen} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Afficher l'utilisateur</Text>
                          </Flex>                        
                        }
                        <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => {setuserUidDelete(user.uid); onOpenDelete()}}>
                          <Icon style={{alignSelf:'center'}} path={mdiAccountRemove} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                          <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Supprimer</Text>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
            </Tbody>
          </Table>
        :
          <Text style={{...TEXT.vsmall, ...TEXT.centered, color: COLORS.pharmBlue}}>Aucun utilisateur membres du jury!</Text>
        }


        <CreateUserModal isOpen={isOpen} onClose={onClose} userData={userData}/>
        <DeleteUserModal isOpenDelete={isOpenDelete} onCloseDelete={onCloseDelete} userUid={userUidDelete}/>
        <ResetPasswordModal isOpenReset={isOpenReset} onCloseReset={onCloseReset} userUid={userUidReset}/>
    </DashboardLayout>
  );
}
