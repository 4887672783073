import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormHelperText,
    FormLabel,
    Select,
    Text
} from '@chakra-ui/react'
import { COLORS, TEXT } from '../styles/global';
import axios from 'axios';

export default function CreateUserModal({isOpen, onClose, userData}) {
    const [username, setusername] = useState("");
    const [usersurname, setusersurname] = useState("");
    const [usercourriel, setusercourriel] = useState("");
    const [usercatype, setusercatype] = useState("");
    const [usercohorte, setusercohorte] = useState("");
    const [userauth, setuserauth] = useState(0);
    const [isdisabled, setisdisabled] = useState(true);
    const [errorMessage, seterrorMessage] = useState("");

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSubmit = async () => {
        setisdisabled(true)
        seterrorMessage("")
        if (userData === null) {
            await axios({
                method: "POST",
                data: {
                    nom: capitalize(username),
                    prenom: capitalize(usersurname),
                    cohorte: usercohorte,
                    courriel: usercourriel,
                    authlevel: userauth,
                    ca_type: usercatype
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
            })
            .then(() => {
                // console.log("Created user")
                setisdisabled(false)
                onClose()
            })
            .catch((err) => {
                if (err.response.status === 500) {
                    seterrorMessage("Un des champs est invalide. Veuillez vous assurer que tous les champs soient remplis et que le courriel soit valide")
                    setisdisabled(false)
                } else {
                    // console.log("Erreur: " + err)
                }
            })
        } else {
            await axios({
                method: "PUT",
                data: {
                    nom: capitalize(username),
                    prenom: capitalize(usersurname),
                    cohorte: usercohorte,
                    courriel: usercourriel,
                    authlevel: userauth,
                    ca_type: usercatype
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + userData.uid
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
            })
            .then(() => {
                // console.log("Modified user")
                setisdisabled(false)
                onClose()
            })
            .catch((err) => {
                if (err.response.status === 500) {
                    seterrorMessage("Un des champs est invalide. Veuillez vous assurer que tous les champs soient remplis et que le courriel soit valide")
                    setisdisabled(false)
                } else {
                    // console.log("Erreur: " + err)
                }
            })            
        }
    }

    const generateInfoTextOnAuth = (authlevel) => {
        switch(parseInt(authlevel)){
            case 0: return "L'étudiant n'a accès qu'à l'interface mobile de base afin d'y consulter ses résultats"
            case 1: return "Le maître de stage perd l'accès à l'interface mobile de base et accède plutôt à l'interface mobile lui permettant d'octroyer des changements de niveau de supervision pour ses étudiants et des badges. (Note: il n'y a aucune contre-vérification de l'identité à partir de cette fonction d'ajout d'utilisateurs. Vous êtes responsables de vous assurer que l'utilisateur soit bien un pharmacien diplômé habilité à superviser des étudiants)"
            case 2: return "Le membre du jury a tous les privilèges du maître de stage et a accès à l'interface web"
            case 3: return "L'administrateur a tous les privilèges du membre du jury et a accès aux fonctionnalités plus avancées sur l'interface web, tel que la gestion des utilisateurs et de l'infrastructure technologique entourant l'application"
        }
    }

    useEffect(() => {
        if (isOpen && userData !== null) {
            setusername(userData.nom)
            setusersurname(userData.prenom)
            setusercourriel(userData.courriel)
            setusercohorte(userData.cohorte)
            setuserauth(userData.auth_level)    
            setusercatype(userData.ca_type)       
            setisdisabled(false)
        } else {
            setusername("")
            setusersurname("")
            setusercourriel("")
            setusercohorte("")
            setusercatype("")
            setuserauth(0)
        }
    }, [isOpen]);
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{userData === null ? "Créer un nouvel utilisateur" : "Modifier un utilisateur"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {errorMessage !== "" && <Text style={{...TEXT.bold, color: COLORS.pharmRed}}>{errorMessage}</Text>}
                    <FormControl id="usersurname" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Prénom de l'utilisateur</FormLabel>
                        <Input type="text" min={0} max={32} value={usersurname} onChange={(val) => {setusersurname(val.target.value); setisdisabled(false)}}/>
                        <FormHelperText>{usersurname.length | 0}/32 caractères</FormHelperText>
                    </FormControl>
                    <FormControl id="username" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Nom de famille de l'utilisateur</FormLabel>
                        <Input type="text" min={0} max={32} value={username} onChange={(val) => setusername(val.target.value)}/>
                        <FormHelperText>{username.length | 0}/32 caractères</FormHelperText>
                    </FormControl>
                    <FormControl id="courriel" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Courriel de l'utilisateur</FormLabel>
                        <Input type="text" min={0} max={64} value={usercourriel} onChange={(val) => setusercourriel(val.target.value)}/>
                        <FormHelperText>{usercourriel.length | 0}/64 caractères. Sera utilisé pour fins de connection</FormHelperText>
                    </FormControl>
                    <FormLabel color={COLORS.pharmBlue}>Niveau d'autorité</FormLabel>
                    <Select mb={1} value={userauth} onChange={(val) => {setusercohorte(""); setusercatype(""); setuserauth(val.target.value)}}>
                        <option value={0}>Étudiant</option>
                        <option value={1}>Maître de stage</option>
                        <option value={2}>Membre du jury</option>
                        <option value={3}>Administrateur</option>
                    </Select>
                    <Text mb={3} color={COLORS.pharmBlue} fontSize='xs'>{generateInfoTextOnAuth(userauth)}</Text>
                    <FormControl id="cohorte" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Type de CA</FormLabel>
                        <Select mb={1} disabled={parseInt(userauth) !== 1} value={usercatype} onChange={(val) => {setusercatype(val.target.value)}}>
                            <option value={'ca_mc'}>C.A. MPAA</option>
                            <option value={'ca_externe'}>C.A. externe (non UdeM)</option>
                            <option value={'ca_es'}>C.A en établissement de santé</option>
                        </Select>
                    </FormControl>
                    <FormControl id="cohorte" mb={3}>
                        <FormLabel color={COLORS.pharmBlue}>Cohorte</FormLabel>
                        <Input type="number" disabled={parseInt(userauth) !== 0} min={0} max={4} value={usercohorte} onChange={(val) => setusercohorte(val.target.value)}/>
                        <FormHelperText>Année de début de la maîtrise, 4 chiffres</FormHelperText>
                    </FormControl>                  
                </ModalBody>
                <ModalFooter>
                <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onClose}>
                    Fermer
                </Button>
                <Button backgroundColor={COLORS.pharmGreen} color="#eee" disabled={isdisabled} onClick={() => handleSubmit()}>{userData === null ? "Créer un utilisateur" : "Modifier l'utilisateur"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
