import React, { useState } from 'react'
import { Flex, Text, Divider, InputGroup, InputLeftAddon, Button, Input, Textarea } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import axios from 'axios'

import DashboardLayout from '../components/layouts/DashboardLayout'

export default function UserImport() {
    const [file, setFile] = useState(null);
    const [isloading, setisloading] = useState(false)
    const [errorMessage, seterrorMessage] = useState(null)
    
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData();
        formData.append("csvFile", file);
        
        setisloading(true)
        seterrorMessage("")
        await axios({
            method: "POST",
            data: formData,
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'usercsvupload'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                seterrorMessage("Erreur serveur, veuillez réessayer plus tard ou contacter l'administrateur")
                return
            }
            seterrorMessage(res.data.message)
        })
        .catch((err) => {
            if (err.response.status === 413) {
                seterrorMessage("Erreur, le fichier est trop volumineux. La limite est de 2mb pour un CSV")
                return
            }
            if (err.response.status === 401) {
                seterrorMessage("Vous n'êtes plus authentifiés. Veuillez recharger la page")
                return
            }
            if (err.response.status === 500) {
                seterrorMessage("Erreur serveur. Veuillez vous assurer que votre fichier soit bien de format CSV")
                return
            }
            // console.log("Erreur: " + err)
            seterrorMessage("Erreur serveur: " + err)
        })
        .finally(() => {
            setisloading(false)
        })
    };
    
    return (
        <DashboardLayout>
        <Flex flexDirection='row'>
        <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Importer une liste d'utilisateurs</Text>
        </Flex>
        <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>
        Cette section vous permet d'importer une liste CSV d'utilisateurs directement dans la base de données de l'application. La liste doit respecter les éléments suivants:
        <Flex paddingLeft={5}>
        <ul>
        <li>
        Fichier de type CSV <strong>sans header (entête)</strong>
        </li>
        <li>
        Doit présenter les champs dans l'ordre suivant: NOM, PRÉNOM, COURRIEL (LOGIN), STATUT (choisir parmi étudiant, ca_es, ca_mc, ca_externe), COHORTE (année de type ####)
        </li>
        </ul>
        </Flex>
        À noter que cette fonction n'est pas destinée à la création d'utilisateurs spéciaux (admin, jury). Veuillez utiliser la fonction Gérer les utilisateurs à cette fin.
        </Text>
        <Divider mt={5} mb={5}/>
        
        <form onSubmit={handleSubmit}>
        <InputGroup>
        <InputLeftAddon children='Fichier .csv' />
        <Input paddingTop={1} type="file" id="csvFile" onChange={handleFileChange} />
        <Button type="submit" isDisabled={isloading}>Envoyer</Button>
        </InputGroup>
        </form>
        {errorMessage && <Textarea paddingTop={5} marginTop={5} size='lg' rows={10} isReadOnly={false}>{errorMessage}</Textarea>}
        
        </DashboardLayout>
        )
    }
