import React, { useEffect, useState, useContext } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Divider, Text, Badge, Flex, Image, Button, useDisclosure, Table, TableCaption, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { TEXT, COLORS, BADGES, JALONS } from '../components/styles/global';
import axios from 'axios';
import { ApiContext } from '../components/context/ApiContext';
import Icon from '@mdi/react'
import { mdiPlaylistPlus, mdiShieldStar, mdiPoll, mdiQrcode, mdiArrowRightDropCircleOutline, mdiArrowDownDropCircleOutline } from '@mdi/js';

import DashboardLayout from '../components/layouts/DashboardLayout';
import ApcStatsStudent from '../components/stats/student/ApcStatsStudent';
import ApcCompletionStudent from '../components/stats/student/ApcCompletionStudent';
import NoteEditor from '../components/stats/student/NoteEditor';
import Loading from '../components/Loading';
import BadgeModal from '../components/stats/student/BadgeModal';
import QrCodeModal from '../components/stats/student/QrCodeModal';

export default function StudentStats() {
    const [searchParams,] = useSearchParams();
    let currentUid = searchParams.get('uid')
    const navigate = useNavigate();
    const Api = useContext(ApiContext)
    const { badge, apc, niveau, isloading } = Api
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isQrOpen, onOpen: onQrOpen, onClose: onQrClose } = useDisclosure()

    const [studentData, setstudentData] = useState({})
    const [apcChartData, setapcChartData] = useState([])
    const [offerBadgeId, setofferBadgeId] = useState(0)
    const [triggerReload, settriggerReload] = useState(false)
    const [userBadges, setuserBadges] = useState([])
    const [userJalons, setuserJalons] = useState([])
    const [userLog, setuserLog] = useState([])

    // UI
    const [showNotesSection, setshowNotesSection] = useState(false)
    const [showHistogramSection, setshowHistogramSection] = useState(false)
    const [showGraphSection, setshowGraphSection] = useState(false)
    const [showJalonSection, setshowJalonSection] = useState(false)
    const [showBadgeSection, setshowBadgeSection] = useState(false)
    const [showEventsSection, setshowEventsSection] = useState(false)

    const handleGiveBadge = (badgeid) => {
        setofferBadgeId(badgeid)
        onOpen()
    }

    useEffect(() => {
        if (currentUid === null) {
            navigate('/student/search?from=/student/stats')
        } 
    }, [currentUid, navigate])

    useEffect(() => {
        const getUserData = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + currentUid
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setstudentData(res.data)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
        }

        const getUserApcs = async() => {
            let _chartData = []
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + currentUid + '/apc/all'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    // APCS start at niveau 1 then adjust at every date point
                    let _lastApcs = {1:1, 2:1, 3:1, 4:1, 5:1, 6:1, 7:1}
                    res.data.forEach((data) => {
                        let _tempData = _lastApcs
                        _tempData['date'] = new Date(data.date_given).toLocaleDateString('fr-CA')
                        _tempData[data.apc_id] = data.niveau_id
                        _lastApcs[data.apc_id] = data.niveau_id
                        _chartData.push({..._tempData})
                    })
                    setapcChartData(_chartData)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })             
        }

        const getUserBadges = async () => {
            await fetch(process.env.REACT_APP_API_SERVER + 'users/' + currentUid + "/badges/all", {
                method: 'GET',
                credentials: 'include'
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === undefined) {
                    let userBadges = data.filter((badge) => badge.badgeid > 3)
                    let userJalons = data.filter((badge) => badge.badgeid <= 3)
    
                    setuserBadges(userBadges)
                    setuserJalons(userJalons)
                }
            })
            .catch(err => {
                // console.log("Failed to get current badges")
                // console.log(err)
            })
        }

        const getUserLog = async () => {
            await axios({
                method: "POST",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'users/' + currentUid + '/log/1'
            })
            .then(data => {
                if (data.message === undefined) {
                    setuserLog(data.data)
                }
            })
            .catch(err => {
                // console.log("Failed to get current badges")
                // console.log(err)
            })
        }

        if (currentUid !== null) {
            getUserData()
            getUserApcs()
            getUserBadges()
            getUserLog()
        }

        if (searchParams.get('badges') === '1') {
            setTimeout(() => {
                window.scrollTo(0,document.body.scrollHeight);
                searchParams.set('badges', 0) 
            }, 750);
        }
    }, [triggerReload, searchParams, currentUid])

    return (
        <DashboardLayout>
            <Flex flexDirection='row'>
                <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Statistiques pour l'étudiant{studentData.nom && ': ' + studentData.prenom + ' ' + studentData.nom + ' '}</Text>
                {studentData.nom &&
                    <Badge ml={3} alignSelf='center' color={COLORS.pharmBlue} style={{...TEXT.h3}}>{studentData.cohorte}</Badge>
                }
            </Flex>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette page vous permet d'analyser le parcours de l'étudiant au sein de ses stages pour fins d'évaluation</Text>
            <Divider mt={5}/>
            <Text mt={5} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Options rapides</Text>
            <Flex flexDirection='row'>
                <Flex p={5} width={150} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/student/list?uid=' + currentUid)}>
                    <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: 'white'}}>Ajouter à une liste</Text>
                </Flex>
                <Flex p={5} width={150} flexDirection='column' style={{borderRadius: 10, borderColor: '#555', cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: '#555'}} onClick={onQrOpen}>
                    <Icon style={{alignSelf:'center'}} path={mdiQrcode} color='#555' _hover={{backgroundColor:"white"}} size={1.2}/>
                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: 'white'}}>Obtenir son code QR</Text>
                </Flex>
            </Flex>
            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowNotesSection(visible => !visible)}>
                <Icon path={showNotesSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text mt={5} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}} _hover={{...COLORS.pharmBlueHover}}>Notes au dossier (non-visible par l'étudiant - notes internes)</Text>
            </Flex>
            { showNotesSection &&
                <NoteEditor uid={currentUid}/>
            }
            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowHistogramSection(visible => !visible)}>
                <Icon path={showHistogramSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text mt={5} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}}>Niveaux de supervision actuels de l'étudiant {apcChartData.length > 1 && "(dernière évaluation le " + new Date(apcChartData.at(-1).date.split("-")[1] + "-" + apcChartData.at(-1).date.split("-")[2] + "-" + apcChartData.at(-1).date.split("-")[0]).toLocaleDateString('fr-CA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + ")"}</Text>
            </Flex>
            { showHistogramSection && (
                apcChartData.length > 1 ?
                    <ApcCompletionStudent chartData={apcChartData.at(-1)}/>
                :
                    <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue, marginLeft: 5}}>L'étudiant n'a reçu aucune évaluation à ce jour</Text>
            )}
            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowGraphSection(visible => !visible)}>
                <Icon path={showGraphSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text mt={5} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}}>Évolution des niveaux de supervision pour chaque APC à travers le temps</Text>
            </Flex>
            {showGraphSection && (
                apcChartData.length > 1 ?
                    <ApcStatsStudent chartData={apcChartData} studentData={studentData}/>
                :
                    <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue, marginLeft: 5}}>L'étudiant n'a reçu aucune évaluation à ce jour</Text>
            )}
            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowJalonSection(visible => !visible)}>
                <Icon path={showJalonSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text mt={5} mb={2} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}}>Consulter et octroyer des jalons</Text>
            </Flex>
            { showJalonSection &&
                <Flex flexDirection="row" justifyContent="center" alignItems="center" width="100%" userSelect="none">
                {Object.values(JALONS).map((jalon, i) => {
                    let obtained = false
                    if (userJalons.length > 0) {
                        obtained = userJalons.filter((badge) => badge.badgeid === i+1)
                    }
                    return (
                        <Flex key={i} flexDirection='column' mr={5} alignItems='center' justifyContent='center'>
                            <Image src={jalon.unnamed} opacity={obtained ? 1 : 0.25} width={100} height={100}/>
                            {obtained.length > 0 ?
                                <Text style={{...TEXT.vsmall, ...TEXT.regular, color: COLORS.pharmBlue}} textAlign='center'>{new Date(obtained[0].date_given).toLocaleDateString('fr-CA')}</Text>
                            :
                                <Button
                                    mt={4}
                                    bgColor={COLORS.pharmBlue}
                                    type="submit"
                                    color="#eee"
                                    _hover={{backgroundColor: COLORS.pharmBlueHover}}
                                    onClick={() => handleGiveBadge(i)}
                                >
                                    Offrir le jalon
                                </Button>
                            }
                        </Flex>
                    )
                })}
                </Flex>
            }
            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowBadgeSection(visible => !visible)}>
                <Icon path={showBadgeSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text id="badgeSection" mt={5} mb={2} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}}>Consulter et octroyer des badges</Text>
            </Flex>
            { showBadgeSection && (isloading ?
                <Loading/>
            :
                <Flex flexDirection="row" justifyContent="center" alignItems="center" width="100%" flexWrap='wrap' userSelect="none">
                {Object.values(BADGES).map((abadge, i) => {
                    let obtained = false
                    let datestring = ""
                    if (userBadges.length > 0) {
                        obtained = userBadges.filter((badge) => badge.badgeid === i+4)
                        if (obtained.length > 0) {
                            for (let obtain of obtained) {
                                datestring += obtain.master_name + " - " + new Date(obtain.date_given).toLocaleDateString('fr-CA') + "\n"
                            }
                        } 
                    }
                    return (
                        <Flex key={i} flexDirection='column' mr={3} alignItems='center' justifyContent='center' mb={5}>
                            <Image src={abadge.named} opacity={obtained ? 1 : 0.25} width={100} height={100}/>
                            <Text style={{...TEXT.small, ...TEXT.regular, color: COLORS.pharmBlue}} textAlign='center'>{badge[i+3] !== undefined ? badge[i+3].titre : "Loading..."}</Text>
                            <Text style={{...TEXT.vsmall, ...TEXT.regular, color: COLORS.pharmBlue, whiteSpace: 'pre-wrap'}} textAlign='center'>{datestring}</Text>
                            <Button
                                mt={4}
                                bgColor={COLORS.pharmBlue}
                                type="submit"
                                color="#eee"
                                _hover={{backgroundColor: COLORS.pharmBlueHover}}
                                onClick={() => handleGiveBadge(i+3)}
                            >
                                Offrir le badge
                            </Button>
                        </Flex>
                    )
                })}
                </Flex>
            )}

            <Flex flexDirection='row' alignItems='center' userSelect='none' marginTop={5} cursor='pointer' onClick={() => setshowEventsSection(visible => !visible)}>
                <Icon path={showEventsSection ? mdiArrowDownDropCircleOutline : mdiArrowRightDropCircleOutline} color={COLORS.pharmBlue} _hover={{...COLORS.pharmBlueHover}} size={1}/>
                <Text id="logSection" mt={5} mb={2} style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5, marginTop: 0}}>Évènements au dossier</Text>
            </Flex>
            { showEventsSection &&
                <Table variant='simple' size='sm'>
                    <TableCaption>Derniers évènements au dossier de l'étudiant</TableCaption>
                    <Thead>
                        <Tr>
                        <Th style={{textAlign:'center'}}>Type d'évènement</Th>
                        <Th>Date</Th>
                        <Th>Maître de stage</Th>
                        <Th>Nom du badge ou APC</Th>
                        <Th>Niveau d'apc (si applicable)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {userLog.length >= 1 && badge[0] !== undefined && apc[0] !== undefined && niveau[0] !== undefined &&
                        userLog.map((logItem, index) => {
                            
                            const getIconJSX = (logType) => {
                                switch(logType) {
                                    case "badge": return <><Icon style={{alignSelf:'center', margin:'auto', color: COLORS.pharmGreen}} path={mdiShieldStar} size={0.8}/><Text userSelect='none' textAlign='center' style={{...TEXT.vsmall, color: COLORS.pharmGreen}}>Badge</Text></>
                                    case "apc": return <><Icon style={{alignSelf:'center', margin:'auto', color: COLORS.pharmBlue}} path={mdiPoll} size={0.8}/><Text userSelect='none' textAlign='center' style={{...TEXT.vsmall, color: COLORS.pharmBlue}}>APC</Text></>
                                    default: return <></>
                                }
                            }

                            const getStringDate = (adate) => {
                                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' };
                                return new Date(adate).toLocaleDateString('fr-CA', options)
                            }

                            const getBadgeOrApcName = (itemId, origin) => {
                                if (origin === "badge") return badge[itemId-1].titre
                                if (origin === "apc") return apc[itemId-1].titre
                                return "Unknown item"
                            }

                            const getNiveauName = (itemId) => { 
                                if (itemId !== null) return <Text color={niveau[itemId-1].color} style={{...TEXT.bold}}>{niveau[itemId-1].titre}</Text>
                                return "-"
                            }

                            return (
                            <Tr key={index}>
                                <Td justifyContent='center'>{getIconJSX(logItem.origin)}</Td>
                                <Td style={{...TEXT.vsmall}}>{getStringDate(logItem.date_given)}</Td>
                                <Td style={{...TEXT.bold}}>{logItem.master_name}</Td>
                                <Td>{getBadgeOrApcName(logItem.badge_or_apc_id, logItem.origin)}</Td>
                                <Td>{getNiveauName(logItem.niveau_id)}</Td>
                            </Tr>
                            )
                        })
                        }
                    </Tbody>
                </Table>
            }

            <BadgeModal isOpen={isOpen} onClose={onClose} badgeId={offerBadgeId} student={studentData} triggerReload={triggerReload} settriggerReload={settriggerReload}/>
            <QrCodeModal isOpen={isQrOpen} onClose={onQrClose} userUid={currentUid}/>
        </DashboardLayout>
    )
}
