import React, { PureComponent, useState, useContext, useEffect } from 'react'
import { LineChart, XAxis, YAxis, Tooltip, Line, Legend, CartesianGrid, ReferenceLine, ResponsiveContainer } from 'recharts'
import { Text } from '@chakra-ui/react'
import Loading from '../../Loading'
import { ApiContext } from '../../context/ApiContext'

export default function ApcStatsStudent({chartData, studentData}) {
    const [highlightedStroke, sethighlightedStroke] = useState(0)
    const [referenceDates, setreferenceDates] = useState([])
    const [canDraw, setcanDraw] = useState(false)
    const Api = useContext(ApiContext)
    const { apc, niveau, paramDates, isloading } = Api

    const formatYAxis = (tickItem) => {
        if (niveau[tickItem-1] !== undefined) {
            return (<Text fontWeight="bold" color={niveau[tickItem-1].color}>{niveau[tickItem-1].titre}</Text>)
        }
    }

    class FormattedYaxis extends PureComponent {
        render () {
            const { x, y, payload } = this.props;
            const numericalVal = payload.value
            const getTextValues = (numericalVal) => {
                if (niveau[numericalVal-1] !== undefined) {
                    return [niveau[numericalVal-1].titre, niveau[numericalVal-1].color]
                }
            }

            return (
                <g transform={`translate(${x},${y})`}>red
                    {getTextValues(numericalVal)[0].split(" ").map((text, index) => {
                        return (
                            <text key={index} x={0} y={index*20} dy={5} textAnchor="end" fill={getTextValues(numericalVal)[1]}>{text}</text>
                        )
                    })}
                </g>
            )
        }
    }

    const formatTooltip = (tooltipLabel) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const _date = tooltipLabel.split("-")
        for (let point of referenceDates) {
            if (point !== -1 && chartData[point].date === tooltipLabel) {
                return new Date(_date[1] + "-" + _date[2] + "-" + _date[0]).toLocaleDateString('fr-CA', options).toUpperCase() + " (Dernier point avant l'évaluation)"
            }
        }
        return new Date(_date[1] + "-" + _date[2] + "-" + _date[0]).toLocaleDateString('fr-CA', options).toUpperCase()
    }
    
    const handleMouseEnter = (o) => {
        sethighlightedStroke(parseInt(o.dataKey, 10))
      };
    
    const handleMouseLeave = () => {        
        sethighlightedStroke(0)
    };

    useEffect(() => {
        if (paramDates.length !== 0 && Object.keys(studentData).length !== 0) {
            let referenceParam = paramDates.filter((date) => date.cohorte === parseInt(studentData.cohorte))
            let _referenceDates = []

            for (let param of referenceParam) {
                let _date = new Date(param.paramvalue)
                _referenceDates.push(chartData.filter((entry) => new Date(entry.date) <= _date).length-1)
            }

            setreferenceDates(_referenceDates)
            setcanDraw(true)
        }
    }, [paramDates, studentData])

    return (
        (isloading || !canDraw) && apc[0] === undefined && niveau[0] === undefined ?
            <Loading/>
        :
            <ResponsiveContainer width="100%" height={500}>
                <LineChart width={1000} height={500} data={chartData} margin={{left:50}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date"/>
                    <YAxis interval="preserveStartEnd" allowDecimals={false} ticks={[1,2,3,4,5]} tickCount={5} tick={<FormattedYaxis/>}/>
                    <Tooltip formatter={formatYAxis} style={{zIndex: 9000}} labelFormatter={formatTooltip} offset={20} allowEscapeViewBox={{x: false, y: true}} wrapperStyle={{backgroundColor: 'white', zIndex: 9999}}/>
                    <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                    {referenceDates.length !== 0 && referenceDates.map((point, index) => {
                        return (
                            <ReferenceLine key={index} x={point} stroke="red" strokeWidth={2} isFront={true}/>
                        )
                    })}
                    <Line type="monotone" dataKey="1" stroke="#4672a8" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 1 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[0].titre : 'Loading...'} />
                    <Line type="monotone" dataKey="2" stroke="#46a87c" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 2 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[1].titre : 'Loading...'}/>
                    <Line type="monotone" dataKey="3" stroke="#a84672" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 3 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[2].titre : 'Loading...'}/>
                    <Line type="monotone" dataKey="4" stroke="#a87c46" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 4 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[3].titre : 'Loading...'}/>
                    <Line type="monotone" dataKey="5" stroke="#7c46a8" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 5 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[4].titre : 'Loading...'}/>
                    <Line type="monotone" dataKey="6" stroke="#a84672" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 6 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[5].titre : 'Loading...'}/>
                    <Line type="monotone" dataKey="7" stroke="#72a846" strokeOpacity={highlightedStroke === 0 || highlightedStroke === 7 ? 1 : 0} activeDot={{ r: 8 }} name={apc[0] !== undefined ? apc[6].titre : 'Loading...'}/>
                </LineChart>
            </ResponsiveContainer>
    )
}
