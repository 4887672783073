import React, { useContext, useState } from 'react';
import { 
    Flex, 
    Text, 
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    useDisclosure
} from '@chakra-ui/react';
import Icon from '@mdi/react'
import { mdiCircleEditOutline } from '@mdi/js';

import { COLORS, TEXT } from '../components/styles/global';
import DashboardLayout from '../components/layouts/DashboardLayout';
import { ApiContext } from '../components/context/ApiContext';
import EditApcModal from '../components/managedata/EditApcModal';
import EditNiveauModal from '../components/managedata/EditNiveauModal';

export default function ManageData() {
    const { isOpen: isOpenApc, onOpen: onOpenApc, onClose: onCloseApc } = useDisclosure()
    const { isOpen: isOpenNiveau, onOpen: onOpenNiveau, onClose: onCloseNiveau } = useDisclosure()
    const Api = useContext(ApiContext)
    const { apc, niveau } = Api

    const [apcDataEdit, setapcDataEdit] = useState({});
    const [niveauDataEdit, setniveauDataEdit] = useState({});

    return (
        <DashboardLayout>
            <Flex flexDirection='row'>
                <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Gestion des APCs et niveaux</Text>
            </Flex>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette section vous permet de modifier le texte, l'icône et/ou la couleur des différents APCs et niveaux tels qu'ils s'affichent dans l'application</Text>
            <Divider mt={5} mb={5}/>

            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>APCs</Text>
            <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue}}>Veuillez consulter https://materialdesignicons.com/ pour la liste des icônes disponibles</Text>
            {apc.length >= 1 &&
            <Table variant='simple' mb={5}>
                <TableCaption>Liste des APCs</TableCaption>
                <Thead>
                <Tr>
                    <Th>Nom de l'APC</Th>
                    <Th>Description de l'APC</Th>
                    <Th>Icône</Th>
                    <Th textAlign='center'>Fonctions</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {apc.map((apc, index) => {
                    return (
                        <Tr key={index}>
                        <Td style={{...TEXT.bold}}>{apc.titre}</Td>
                        <Td>{apc.description}</Td>
                        <Td>{apc.icon}</Td>
                        <Td>
                            <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.yellow, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.yellow}} onClick={() => {setapcDataEdit(apc); onOpenApc()}}>
                            <Icon style={{alignSelf:'center'}} path={mdiCircleEditOutline} color={COLORS.yellow} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Modifier</Text>
                            </Flex>
                        </Td>
                        </Tr>
                    )
                    })}
                </Tbody>
            </Table>
            }

            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Niveaux de supervision</Text>
            {niveau.length >= 1 &&
            <Table variant='simple' mb={5}>
                <TableCaption>Liste des niveaux de supervision</TableCaption>
                <Thead>
                <Tr>
                    <Th>Nom du niveau de supervision</Th>
                    <Th>Description du niveau de supervision</Th>
                    <Th>Couleur d'affichage</Th>
                    <Th textAlign='center'>Fonctions</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {niveau.map((niveau, index) => {
                    return (
                        <Tr key={index}>
                        <Td style={{...TEXT.bold}}>{niveau.titre}</Td>
                        <Td>{niveau.description}</Td>
                        <Td><Text style={{...TEXT.bold, color: niveau.color}}>{niveau.color}</Text></Td>
                        <Td>
                            <Flex p={5} flex={0.33} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.yellow, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2, paddingTop:0, paddingBottom:1}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.yellow}} onClick={() => {setniveauDataEdit(niveau); onOpenNiveau()}}>
                            <Icon style={{alignSelf:'center'}} path={mdiCircleEditOutline} color={COLORS.yellow} _hover={{backgroundColor:"white"}} size={1.2}/>
                            <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Modifier</Text>
                            </Flex>
                        </Td>
                        </Tr>
                    )
                    })}
                </Tbody>
            </Table>
            }

        <EditApcModal isOpenApc={isOpenApc} onCloseApc={onCloseApc} apcData={apcDataEdit}/>
        <EditNiveauModal isOpenNiveau={isOpenNiveau} onCloseNiveau={onCloseNiveau} niveauData={niveauDataEdit}/>
        </DashboardLayout>
    );
}
