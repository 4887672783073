import React, { useContext } from 'react'
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, Rectangle } from 'recharts'
import Loading from '../../Loading';
import { ApiContext } from '../../context/ApiContext';
import { COLORS } from '../../styles/global';

export default function MasterBarStats({chartData}) {
    const Api = useContext(ApiContext)
    const { isloading } = Api

    const CustomBar = (props) => {
        const {color} = props;
        let bgcolor = color

        if (bgcolor === undefined) bgcolor = COLORS.pharmGreen
        return <Rectangle {...props} fill={bgcolor} />
      };
      

    return (
        isloading ?
            <Loading/>
        :
            <ResponsiveContainer width="100%" height={700}>
                <BarChart layout="vertical" stackOffset="expand" width={450} height={300} data={chartData} margin={{left:30}}>
                    <XAxis type="number" allowDecimals={false}/>
                    <YAxis type="category" title="Quantité d'utilisateurs" dataKey="name" width={80}/>
                    <Tooltip/>
                    <Bar dataKey="count" name="Compte" shape={CustomBar}/>
                </BarChart>
            </ResponsiveContainer>
    );
}
