import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './context/AuthContext'
import Loading from './Loading';

const PrivateRoute = () => {
    return (
        <AuthContext.Consumer>
            {({ isAuthenticated, isLoading}) => (
                isLoading ?
                    <Loading/>
                :
                    isAuthenticated ? <Outlet /> : <Navigate to="/login" />
            )}
        </AuthContext.Consumer>
    )
}

export default PrivateRoute