import React, { useEffect, useState, useContext } from 'react'
import { Text, Stack, Button, Divider, Flex } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import axios from 'axios'
import { ApiContext } from '../components/context/ApiContext'

import DashboardLayout from '../components/layouts/DashboardLayout'
import Loading from '../components/Loading'
import UserStats from '../components/stats/cohorte/UserStats'
import ApcStats from '../components/stats/cohorte/ApcStats'

export default function CohorteStats() {
    const Api = useContext(ApiContext)
    const { apc } = Api

    // STATE 
    const [cohortes, setcohortes] = useState([])
    const [cohorte, setcohorte] = useState(null)

    // CHARTS
    const [chartGeneralData, setchartGeneralData] = useState([])
    const [chartApcData, setchartApcData] = useState([])

    // UI
    const [isLoading, setisLoading] = useState(true)
    const [iscohorteLoading, setiscohorteLoading] = useState(false)

    const getCohorteData = async (cohorte) => {
        setiscohorteLoading(true)
        setchartApcData([])
        setchartGeneralData([])
        setcohorte(cohorte)
        await axios({
            method: "POST",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'cohortes/' + cohorte + '/users'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                setchartGeneralData([
                    {name: 'Étudiants inscrits', count: res.data.length}, 
                    {name: 'Comptes non associés', count: res.data.filter(user => user.claimed === 0).length},
                    {name: 'Atteinte du jalon 1', count: res.data.filter(user => user.hasJalon1 === 1).length},
                    {name: 'Atteinte du jalon 2', count: res.data.filter(user => user.hasJalon2 === 1).length},
                    {name: 'Atteinte du jalon 3', count: res.data.filter(user => user.hasJalon3 === 1).length},
                    {name: 'Possèdent un badge', count: res.data.filter(user => user.hasBadge === 1).length}
                ])

                let userUids = [];
                res.data.forEach(user => {
                    userUids.push(user.uid)
                });

                if (userUids.length >= 1) {
                    fetchApcsForUsers(userUids, res.data.length)
                }
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        }) 
        setiscohorteLoading(false)
    }

    useEffect(() => {
        const getCohortes = async () => {
            await axios({
                method: "GET",
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'cohortes'
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                } else {
                    setcohortes(res.data)
                }
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            }) 
            setisLoading(false)
        }

        getCohortes()
    }, [])

    const computeApcChartData = (ApcData, cohorteSize) => {
        let data = [
            {
                name: apc[0].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 1).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 1).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 1).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 1).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 1).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[1].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 2).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 2).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 2).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 2).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 2).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[2].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 3).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 3).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 3).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 3).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 3).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[3].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 4).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 4).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 4).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 4).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 4).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[4].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 5).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 5).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 5).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 5).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 5).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[5].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 6).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 6).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 6).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 6).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 6).filter(apcd => apcd.niveau_id === 5).length
            },
            {
                name: apc[6].titre,
                niv_1: cohorteSize - ApcData.filter(apcd => apcd.apc_id === 7).filter(apcd => apcd.niveau_id > 1).length,
                niv_2: ApcData.filter(apcd => apcd.apc_id === 7).filter(apcd => apcd.niveau_id === 2).length,
                niv_3: ApcData.filter(apcd => apcd.apc_id === 7).filter(apcd => apcd.niveau_id === 3).length,
                niv_4: ApcData.filter(apcd => apcd.apc_id === 7).filter(apcd => apcd.niveau_id === 4).length,
                niv_5: ApcData.filter(apcd => apcd.apc_id === 7).filter(apcd => apcd.niveau_id === 5).length
            }
        ]

        setchartApcData(data)
    }

    const fetchApcsForUsers = async (userUids, cohorteSize) => {
        await axios({
            method: "POST",
            data: {
                userlist: userUids
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/apcsforusers'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            } else {
                computeApcChartData(res.data, cohorteSize)
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        }) 
    }

    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Statistiques générales par cohorte</Text>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Veuillez sélectionner la cohorte à analyser</Text>
            {isLoading && <Loading/>}
            <Stack direction='row' p={4}>
            {cohortes.map((cohorte, i) => {
                return (
                    <Button key={i} bgColor={COLORS.pharmBlue} color="#eee" _hover={{backgroundColor: COLORS.pharmBlueHover}} onClick={() => getCohorteData(cohorte['cohorte'])}>{cohorte['cohorte']}</Button>
                )
            })}
            </Stack>
            <Divider mb={3}/>
            {iscohorteLoading && <Loading/>}
            {cohorte !== null &&
                <Text style={{...TEXT.small, color: COLORS.pharmBlue}}>Statistiques générales pour la cohorte débutant l'année {cohorte}</Text>
            }
            {chartGeneralData.length >= 1 &&
                <UserStats chartData={chartGeneralData}/>
            }
            {cohorte !== null &&
                <Flex flexDirection='column'>
                    <Text style={{...TEXT.small, color: COLORS.pharmBlue}} mt={5}>Progression de la cohorte {cohorte} à travers les différents APCs</Text>
                    {chartApcData.length >= 1 ?
                        <ApcStats chartData={chartApcData}/>
                    :
                        <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue}} mt={5}>Aucun étudiant de cette cohorte n'a reçu d'évaluation à ce jour</Text>
                    }
                </Flex>
            }
        </DashboardLayout>
    )
}
