import React from 'react'
import { Text } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'

import DashboardLayout from '../components/layouts/DashboardLayout'

export default function Dashboard() {
    return (
        <DashboardLayout>
            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Veuillez sélectionner l'option désirée dans le sous-menu de gauche</Text>
        </DashboardLayout>
    )
}
