export const COLORS = {
    pharmBlue: '#4672a8',
    pharmBlueHover: '#7599c6',
    pharmGreen: '#72a846',
    pharmGreenHover: '#8eb96a',
    pharmGreenRGB: 'rgb(114,168,70)',
    pharmRed: '#a83746',
    yellow: '#bdbc31'
}

export const TEXT = {
    h1: {
        fontSize: 48
    },
    h2: {
        fontSize: 36
    },
    h3: {
        fontSize: 24
    },
    small: {
        fontSize: 18
    },
    vsmall: {
        fontSize: 14
    },
    hpadded: {
        paddingHorizontal: 10
    },
    regular: {
        fontFamily: 'OpenSans_400Regular'
    },
    bold: {
        fontFamily: 'OpenSans_700Bold',
        fontWeight: 'bold'
    },
    centered: {
        alignSelf: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    }
}

export const IMAGES = {
    logo: '/logo.png',
    icon: '/icon.png'
}

export const BADGES = {
    exception: {
        named: '/assets/badges/named/Exception.png',
        unnamed: '/assets/badges/unnamed/Exception.png'
    },
    professionnel: {
        named: '/assets/badges/named/Professionnel.png',
        unnamed: '/assets/badges/unnamed/Professionnel.png'      
    },
    communicateur: {
        named: '/assets/badges/named/Communicateur.png',
        unnamed: '/assets/badges/unnamed/Communicateur.png'
    },
    collaborateur: {
        named: '/assets/badges/named/Collaborateur.png',
        unnamed: '/assets/badges/unnamed/Collaborateur.png'
    },
    scientifique: {
        named: '/assets/badges/named/Scientifique.png',
        unnamed: '/assets/badges/unnamed/Scientifique.png'        
    },
    erudit: {
        named: '/assets/badges/named/Erudit.png',
        unnamed: '/assets/badges/unnamed/Erudit.png'
    },
    leader: {
        named: '/assets/badges/named/Leader.png',
        unnamed: '/assets/badges/unnamed/Leader.png'
    }
}

export const JALONS = {
    jalon_1: {
        unnamed: '/assets/jalons/Milestone_1.png'
    },
    jalon_2: {
        unnamed: '/assets/jalons/Milestone_2.png'
    },
    jalon_3: {
        unnamed: '/assets/jalons/Milestone_3.png'
    }
}