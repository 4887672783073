import { Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { COLORS, TEXT } from '../../styles/global'
import Icon from '@mdi/react'
import { mdiAccountSearchOutline, mdiMessageBulleted } from '@mdi/js';

import NoteEditor from '../student/NoteEditor';

export default function MasterEntry({master}) {
    const navigate = useNavigate();
    const [showEditor, setshowEditor] = useState(false)

    return (
        <Flex flexDirection='column' p={5} style={{borderWidth: 1, borderRadius: 10}} userSelect="none" _hover={{borderColor: COLORS.pharmBlueHover}}>
            <Flex flexDirection='row'>
                <Flex flexDirection='column' flex={0.5} justifyContent='center'>
                    <Text style={{...TEXT.bold, ...TEXT.h3}}>{master.prenom} {master.nom}</Text>
                </Flex>
                <Flex flexDirection='row' flex={0.5}>
                    <Flex p={5} flex={0.5} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/master/stats?uid=' + master.uid)}>
                        <Icon style={{alignSelf:'center'}} path={mdiAccountSearchOutline} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Statistiques</Text>
                    </Flex>
                    <Flex p={5} flex={0.5} flexDirection='column' style={{borderRadius: 10, borderColor: "#333", cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: "#333"}} onClick={() => setshowEditor(!showEditor)}>
                        <Icon style={{alignSelf:'center'}} path={mdiMessageBulleted} color="#333" _hover={{backgroundColor:"white"}} size={1.2}/>
                        <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Lire ou éditer notes</Text>
                    </Flex>
                </Flex>
            </Flex>
            {showEditor &&
                <NoteEditor uid={master.uid}/>
            }
        </Flex>
    )
}
