import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import { COLORS, TEXT } from '../styles/global';
import axios from 'axios';

export default function ResetPasswordModal({isOpenReset, onCloseReset, userUid}) {
    const [isdisabled, setisdisabled] = useState(false);

    const handleSubmitReset = async () => {
        setisdisabled(true)
        await axios({
            method: "POST",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'users/' + userUid + '/reset'
        })
        .then(() => {
            setisdisabled(false)
            onCloseReset()
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })
    }

    return (
        <Modal isOpen={isOpenReset} onClose={onCloseReset} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Réinitialiser le mot de passe</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text style={{...TEXT.bold, color: COLORS.pharmRed}}>
                        Cette opération retirera le mot de passe de l'utilisateur et rendra le compte accessible 
                        à tous. Assurez-vous que l'utilisateur puisse se connecter rapidement afin d'initialiser 
                        son nouveau mot de passe.
                    </Text>                
                </ModalBody>
                <ModalFooter>
                <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onCloseReset}>
                    Fermer
                </Button>
                <Button backgroundColor={COLORS.pharmRed} color="#eee" disabled={isdisabled} onClick={handleSubmitReset}>Réinitialiser le mot de passe</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
