import React, { useState } from 'react'
import { Flex, Text, Divider, Button, Textarea } from '@chakra-ui/react'
import { COLORS, TEXT } from '../components/styles/global'
import axios from 'axios'

import DashboardLayout from '../components/layouts/DashboardLayout'

export default function DatabaseExport() {
    const [dumpSchema, setdumpSchema] = useState("")
    const [dumpData, setdumpData] = useState("")
    const [isloading, setisloading] = useState(false)

    const handleGenerate = async () => {
        setisloading(true)
        await axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'getdump'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                setdumpSchema("Erreur serveur")
                // console.log("Erreur serveur")
            }
            setdumpSchema(res.data.dbData.dump.schema)
            setdumpData(res.data.dbData.dump.data)
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
            setdumpSchema("Erreur serveur")
        })
        .finally(() => {
            setisloading(false)
        })
    }

    return (
        <DashboardLayout>
            <Flex flexDirection='row'>
                <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Exporter la base de données</Text>
            </Flex>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>Cette section vous permet d'obtenir un export (dump) de la base de données MySQL. À utiliser avec prudence - toutes les données seront exposées.</Text>
            <Divider mt={5} mb={5}/>
            <Button
                mt={4}
                mb={5}
                bgColor={COLORS.pharmBlue}
                type="submit"
                color="#eee"
                _hover={{backgroundColor: COLORS.pharmBlueHover}}
                onClick={() => handleGenerate()}
                isDisabled={isloading}
            >
                Générer le dump
            </Button>
            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Schema</Text>
            <Textarea
                value={dumpSchema}
                size='lg'
                height={500}
                mb={5}
            />
            <Text style={{...TEXT.h3, color: COLORS.pharmBlue, marginLeft: 5}}>Data</Text>
            <Textarea
                value={dumpData}
                size='lg'
                height={1000}
            />
        </DashboardLayout>
    )
}
