import React, { useContext, useEffect } from 'react'
import { Text, Divider, Flex, Heading, Stack, Image} from '@chakra-ui/react'
import { COLORS, IMAGES } from '../components/styles/global'
import { useNavigate } from 'react-router-dom'

import DefaultLayout from '../components/layouts/DefaultLayout'
import LoginForm from '../components/LoginScreen/LoginForm'
import { AuthContext } from '../components/context/AuthContext'
import svgBackground from '../components/styles/background.svg'

export default function LoginScreen() {
    const { isAuthenticated } = useContext(AuthContext)
    let navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/")
        }
    }, [isAuthenticated, navigate])

    return (
        <Flex direction="row" flexWrap="wrap" justifyContent="center" height="100vh" width="100vw" style={{backgroundImage: `url(${svgBackground})`, backgroundSize: 'cover'}}>
            <DefaultLayout bgColor="white">
                <Stack spacing={2} color={COLORS.pharmBlue} textAlign="center">
                    <Image src={IMAGES.icon} width={100} height={100} alignSelf="center"/>
                    <Heading fontSize={48}>Ma Résidence - panneau de configuration</Heading>
                    <Divider></Divider>
                    <Text>Accédez aux fonctions d'analytique, de gestion et de maintenance pour l'application Ma Résidence</Text>
                    <Divider></Divider>
                    <Text fontWeight="bold">Déja enregistré ? Connectez-vous pour accéder aux fonctionnalités !</Text>
                    <LoginForm/>
                </Stack>
            </DefaultLayout>
        </Flex>
    )
}
