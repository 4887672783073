import React, { useEffect, useState } from 'react'
import { Divider, Text, Flex, FormLabel, FormControl, Input, FormHelperText, Stack, Box } from '@chakra-ui/react'
import { TEXT, COLORS } from '../components/styles/global'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiPlusBoxOutline, mdiDeleteForever, mdiPlaylistPlus, mdiAccountSearchOutline } from '@mdi/js';
import axios from 'axios';

import DashboardLayout from '../components/layouts/DashboardLayout'

export default function StudentList() {
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();
    const [lists, setlists] = useState([])
    const [listname, setlistname] = useState("")
    const [listdesc, setlistdesc] = useState("")
    const [error, seterror] = useState(false)

    const updateLists = async () => {
        await axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/list'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            }
            setlists(res.data)
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
            setlists([])
        })
    }

    const addToList = async (listid) => {
        if (searchParams.get('uid') !== null) {
            await axios({
                method: "POST",
                data: {
                    listid: listid
                },
                withCredentials: true,
                url: process.env.REACT_APP_API_SERVER + 'stats/list/' + searchParams.get('uid')
            })
            .then(res => {
                if (![200, 401].includes(res.status)) {
                    // console.log("Erreur serveur")
                }
                seterror("L'étudiant a été ajouté à la liste!")
            })
            .catch((err) => {
                // console.log("Erreur: " + err)
            })

            updateLists()
        }
    }

    const createList = async () => {
        await axios({
            method: "POST",
            data: {
                listName: listname,
                listDesc: listdesc
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/list'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            }
            // console.log("Created list " + res.data)
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })

        seterror(false)
        updateLists()
    }

    const removeList = async (listid) => {
        await axios({
            method: "DELETE",
            data: {
                listid: listid
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'stats/list'
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                // console.log("Erreur serveur")
            }
        })
        .catch((err) => {
            // console.log("Erreur: " + err)
        })

        seterror(false)
        updateLists()
    }

    useEffect(() => {
        updateLists()
    }, [])

    return (
        <DashboardLayout>
            <Text style={{...TEXT.h2, color: COLORS.pharmBlue, marginLeft: 5}}>Listes d'étudiants</Text>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}}>
                Cette page vous permet de générer des listes d'étudiants afin de retrouver rapidement les dossiers
                nécessitant une attention particulière. Peut être utilisé, par exemple, pour regrouper les étudiants
                en difficulté, les étudiants prometteurs (pour l'octroi de badges), les étudiants de certains milieux, etc. 
            </Text>
            <Divider mt={5}/>
            {searchParams.get('uid') !== null &&
                <Box bg={COLORS.pharmGreen} color="#eee" width="100%" p={4} borderRadius={10} userSelect='none'>
                    <Text style={{...TEXT.bold}}>Vous avez été redirigé</Text>
                    <Text style={{...TEXT.regular}}>
                        Vous pouvez ajouter l'étudiant sélectionné à une des listes déjà existantes ci-bas, ou en créer une nouvelle
                    </Text>
                </Box>
            }
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}} mt={5}>Créer une liste</Text>
            <Flex direction="row" justifyContent="center" alignItems="center" borderRadius={10} borderWidth={1} p={3}>
                <Flex flexDirection="column" flex={0.8} mr={5}>
                    <FormControl id="listName">
                        <FormLabel color={COLORS.pharmBlue}>Nom de la liste</FormLabel>
                        <Input type="text" min={0} max={128} onChange={(val) => setlistname(val.target.value)}/>
                    </FormControl>
                    <FormControl id="listDesc">
                        <FormLabel color={COLORS.pharmBlue}>Descriptif</FormLabel>
                        <Input type="text" min={0} max={256} onChange={(val) => setlistdesc(val.target.value)}/>
                        <FormHelperText>Description sommaire de la liste. Facultatif</FormHelperText>
                    </FormControl>
                </Flex>
                <Flex p={5} flex={0.2} flexDirection='column' style={{borderRadius: 10, cursor: 'pointer', color: 'white', backgroundColor: "#333" }} userSelect="none" onClick={() => createList()}>
                    <Icon style={{alignSelf:'center'}} path={mdiPlusBoxOutline} color="white" size={1.2}/>
                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Créer la liste</Text>
                </Flex>
            </Flex>
            <Text style={{...TEXT.small, color: COLORS.pharmBlue, marginLeft: 5}} mt={5}>Consulter ou modifier une liste</Text>
            {lists.length === 0 &&
                <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue, marginLeft: 5}} mt={5}>Aucune liste n'a été créée encore!</Text>
            }
            {error &&
                <Text mt={3} style={{...TEXT.bold, ...TEXT.vsmall, color: COLORS.pharmRed}}>{error}</Text>
            }
            <Stack direction="column" overflowY="auto" overflowX="hidden" maxHeight="55vh">
            {lists.length !== 0 && lists.map((list, index) => {
                return (
                    <Flex key={index} flexDirection='row' width="100%" justifyContent="center" alignItems="center" borderRadius={10} borderWidth={1} p={3}>
                        <Flex flex={0.2} flexDirection='column' borderRightWidth={1} justifyContent="center" alignItems="center" userSelect="none">
                            <Text style={{...TEXT.h1, color: COLORS.pharmBlue}}>{list.usercount}</Text>
                            <Text style={{...TEXT.vsmall, color: COLORS.pharmBlue}}>Étudiant(s)</Text>
                        </Flex>
                        <Flex flex={0.4} flexDirection='column' ml={3}>
                            <Text style={{...TEXT.h3, color: COLORS.pharmBlue}}>{list.listname}</Text>
                            <Text style={{...TEXT.small, color: COLORS.pharmBlue}}>{list.listdesc}</Text>
                        </Flex>
                        {searchParams.get('uid') === null ?
                            <Flex flex={0.4}>
                                <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/student/search?from=/student/list&listid=' + list.listid + '&read=true')}>
                                    <Icon style={{alignSelf:'center'}} path={mdiAccountSearchOutline} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Consulter</Text>
                                </Flex>
                                <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => navigate('/student/search?from=/student/list&listid=' + list.listid + '&read=false')}>
                                    <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Ajouter des étudiants</Text>
                                </Flex>
                                <Flex p={5} flex={0.20} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmRed, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmRed}} onClick={() => removeList(list.listid)}>
                                    <Icon style={{alignSelf:'center'}} path={mdiDeleteForever} color={COLORS.pharmRed} _hover={{backgroundColor:"white"}} size={1.2}/>
                                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Supprimer</Text>
                                </Flex>
                            </Flex>
                        :
                            <Flex flex={0.4}>
                                <Flex p={5} flexDirection='column' style={{borderRadius: 10, borderColor: COLORS.pharmBlue, cursor: 'pointer', transition: 'all 0.75s ease', marginRight: 2}} justifyContent='flex-start' userSelect="none" _hover={{backgroundColor: COLORS.pharmBlue}} onClick={() => addToList(list.listid)}>
                                    <Icon style={{alignSelf:'center'}} path={mdiPlaylistPlus} color={COLORS.pharmBlue} _hover={{backgroundColor:"white"}} size={1.2}/>
                                    <Text style={{...TEXT.vsmall, ...TEXT.centered, ...TEXT.bold, color: "white"}}>Ajouter l'étudiant à cette liste</Text>
                                </Flex>
                            </Flex>
                        }
                    </Flex>
                )
            })}
            </Stack>
        </DashboardLayout>
    )
}
