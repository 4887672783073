import React from 'react';
import "./App.css"
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Auth } from './components/context/AuthContext'

import PrivateRoute from './components/PrivateRoute'
import LoginScreen from './views/LoginScreen'
import RegisterScreen from './views/RegisterScreen'
import Dashboard from './views/Dashboard';
import Populate from './views/Populate';
import CohorteStats from './views/CohorteStats';
import StudentSearch from './views/StudentSearch';
import MasterSearch from './views/MasterSearch';
import StudentStats from './views/StudentStats';
import StudentList from './views/StudentList'
import { ApiProvider } from './components/context/ApiContext';
import Stages from './views/Stages';
import Bugs from './views/Bugs';
import ManageUsers from './views/ManageUsers';
import ManageData from './views/ManageData';
import DatabaseExport from './views/DatabaseExport';
import MasterStats from './views/MasterStats';
import UserImport from './views/UserImport';

function App() {
  return (
    <Auth>
      <ApiProvider>
        <Router>
            <Routes>
              {/* COMMON ROUTES */}
              <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<Dashboard/>}/>
              </Route>
              <Route exact path='/login' element={<LoginScreen/>}/>
              <Route exact path='/register' element={<RegisterScreen/>}/>

              {/* PROTECTED ROUTES */}
              <Route exact path='/populate' element={<PrivateRoute/>}>
                <Route exact path='/populate' element={<Populate/>}/>
              </Route>
              <Route exact path='/cohortestats' element={<PrivateRoute/>}>
                <Route exact path='/cohortestats' element={<CohorteStats/>}/>
              </Route>
              <Route exact path='/student/search' element={<PrivateRoute/>}>
                <Route exact path='/student/search' element={<StudentSearch/>}/>
              </Route>
              <Route exact path='/master/search' element={<PrivateRoute/>}>
                <Route exact path='/master/search' element={<MasterSearch/>}/>
              </Route>
              <Route path='/student/stats' element={<PrivateRoute/>}>
                <Route exact path='/student/stats' element={<StudentStats/>}/>
              </Route>
              <Route path='/master/stats' element={<PrivateRoute/>}>
                <Route exact path='/master/stats' element={<MasterStats/>}/>
              </Route>
              <Route path='/student/list' element={<PrivateRoute/>}>
                <Route exact path='/student/list' element={<StudentList/>}/>
              </Route>
              <Route path='/stages' element={<PrivateRoute/>}>
                <Route exact path='/stages' element={<Stages/>}/>
              </Route>
              <Route path='/bugs' element={<PrivateRoute/>}>
                <Route exact path='/bugs' element={<Bugs/>}/>
              </Route>
              <Route path='/manageusers' element={<PrivateRoute/>}>
                <Route exact path='/manageusers' element={<ManageUsers/>}/>
              </Route>
              <Route path='/managedata' element={<PrivateRoute/>}>
                <Route exact path='/managedata' element={<ManageData/>}/>
              </Route>
              <Route path='/databaseexport' element={<PrivateRoute/>}>
                <Route exact path='/databaseexport' element={<DatabaseExport/>}/>
              </Route>
              <Route path='/userimport' element={<PrivateRoute/>}>
                <Route exact path='/userimport' element={<UserImport/>}/>
              </Route>

              {/* FALLBACK */}
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
            </Routes>
        </Router>
      </ApiProvider>
    </Auth>
  );
}

export default App;