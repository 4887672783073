import React, { useEffect, useState, useContext } from 'react'
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from 'recharts'
import { ApiContext } from '../../context/ApiContext';
import { COLORS } from '../../styles/global';

import Loading from '../../Loading';

export default function ApcCompletionStudent({chartData}) {
    const [radarData, setradarData] = useState([])
    const Api = useContext(ApiContext)
    const { apc, niveau, isloading } = Api

    useEffect(() => {
        if (apc[6] !== undefined) {
            let radarChartData = [];
            for (let i = 0; i <= 6; i++) {
                radarChartData.push({apc: apc[i].titre, niveau: chartData[i+1]})
            }

            setradarData(radarChartData)
        }
    }, [apc, chartData])

    const formatTicks = (tickItem) => {
        if (niveau[tickItem-1] !== undefined) {
            return niveau[tickItem-1].titre
        }
    }

    return (
        isloading ?
            <Loading/>
        :
            <ResponsiveContainer width="100%" height={300}>
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="apc" type="category" color={COLORS.pharmBlue}/>
                    <PolarRadiusAxis tickFormatter={formatTicks} type="number" angle={0} axisLine={false} orientation="middle" tickCount={6} domain={[0, 5]}/>
                    <Radar name="Niveau de supervision" dataKey="niveau" type="category" stroke={COLORS.pharmBlue} fill={COLORS.pharmBlue} fillOpacity={0.6} />
                </RadarChart>
            </ResponsiveContainer>
    )
}
