import React, { useState, useEffect, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormHelperText,
    FormLabel,
    Text,
    Textarea
} from '@chakra-ui/react'
import { COLORS, TEXT } from '../styles/global';
import axios from 'axios';

import { ApiContext } from '../context/ApiContext';

export default function EditApcModal({isOpenApc, onCloseApc, apcData}) {
    const [errorMessage, seterrorMessage] = useState("");
    const [isdisabled, setisdisabled] = useState(false);
    const Api = useContext(ApiContext)
    const { fetchApc } = Api

    const [apcTitre, setapcTitre] = useState("");
    const [apcDesc, setapcDesc] = useState("");
    const [apcIcon, setapcIcon] = useState("");

    const handleSubmit = async () => {
        setisdisabled(true)
        seterrorMessage("")
        await axios({
            method: "PUT",
            data: {
                titre: apcTitre,
                description: apcDesc,
                icon: apcIcon
            },
            withCredentials: true,
            url: process.env.REACT_APP_API_SERVER + 'apc/' + apcData.id
        })
        .then(res => {
            if (![200, 401].includes(res.status)) {
                seterrorMessage("Erreur serveur. Problème de connection, ou vous n'êtes pas autorisés à modifier l'APC")
            }
        })
        .then(() => {
            setisdisabled(false)
            fetchApc()
            onCloseApc()
        })
        .catch((err) => {
            if (err.response.status === 500) {
                seterrorMessage("Un des champs est invalide. Veuillez vous assurer que tous les champs soient remplis adéquatement.")
            } else {
                // console.log("Erreur: " + err)
            }
            setisdisabled(false)
        })
    }

    useEffect(() => {
        if (isOpenApc && apcData !== null) {
            setapcTitre(apcData.titre)
            setapcDesc(apcData.description)
            setapcIcon(apcData.icon)
        }
    }, [isOpenApc]);

    return (
    <Modal isOpen={isOpenApc} onClose={onCloseApc} size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Modifier l'APC</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {errorMessage !== "" && <Text style={{...TEXT.bold, color: COLORS.pharmRed}}>{errorMessage}</Text>}
                <FormControl id="apctitre" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Titre de l'APC</FormLabel>
                    <Input type="text" min={0} max={255} value={apcTitre} onChange={(val) => {setapcTitre(val.target.value)}}/>
                    <FormHelperText>{apcTitre.length | 0}/255 caractères</FormHelperText>
                </FormControl>
                <FormControl id="apcdesc" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Description de l'APC</FormLabel>
                    <Textarea min={0} max={512} value={apcDesc} onChange={(val) => setapcDesc(val.target.value)}/>
                    <FormHelperText>{apcDesc.length | 0}/512 caractères</FormHelperText>
                </FormControl>
                <FormControl id="apcicon" mb={3}>
                    <FormLabel color={COLORS.pharmBlue}>Icône associé à l'APC</FormLabel>
                    <Input type="text" min={0} max={64} value={apcIcon} onChange={(val) => setapcIcon(val.target.value)}/>
                    <FormHelperText>{apcIcon.length | 0}/64 caractères. Voir https://materialdesignicons.com/</FormHelperText>
                </FormControl>                 
            </ModalBody>
            <ModalFooter>
            <Button backgroundColor={COLORS.pharmBlue} color="#eee" mr={3} onClick={onCloseApc}>
                Fermer
            </Button>
            <Button backgroundColor={COLORS.pharmGreen} color="#eee" disabled={isdisabled} onClick={() => handleSubmit()}>Modifier l'APC</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    );
}
